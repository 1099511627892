import React, {useEffect, useRef, useState} from "react";
import {Route, Link, NavLink, useNavigate, useLocation, Outlet} from "react-router-dom";

import {InputText} from "primereact/inputtext";
import {Badge} from "primereact/badge";
import {StyleClass} from "primereact/styleclass";
import {Ripple} from "primereact/ripple";
import {Toast} from "primereact/toast";
import {Menu} from 'primereact/menu';

import Avatar from 'react-avatar';

import {Helmet} from "react-helmet-async";

import {classNames} from "primereact/utils";
import PrimeReact from "primereact/api";

import {useLazyGetMeQuery} from "../../features/auth/userApi";
import {useLoginUserMutation, useLoginUserWithCookieMutation, useLogoutUserMutation} from '../../features/auth/authApi';
import {useAppSelector, useAppDispatch} from "../../app/store";

import {appApi} from "../../features/app/appApi"
import {authApi} from "../../features/auth/authApi"
import {systemApi} from "../../features/system/systemApi"

import {toast} from 'react-toastify';
import {useNotificationCenter} from "react-toastify/addons/use-notification-center"

import {useCookies} from "react-cookie";
import {AuthContext} from "../authProvider";
import {PanelMenu} from "primereact/panelmenu";

import {
  HiBriefcase,
  HiCash,
  HiClipboardCheck, HiClipboardList, HiCog,
  HiDocument,
  HiDocumentAdd,
  HiDocumentRemove,
  HiDocumentSearch, HiEye, HiOutlineBriefcase, HiOutlineDocument, HiOutlineDocumentAdd,
  HiOutlineDocumentRemove, HiOutlineDocumentReport,
  HiOutlineDocumentSearch, HiUserGroup
} from "react-icons/hi";
import {HiCog6Tooth, HiDocumentCheck, HiOutlineUserGroup, HiUser} from "react-icons/hi2";

import "./sidebar.css"
import ctatLogo from "../../assets/logo/ctat.jpg"
import tacbaLogo from "../../assets/logo/tacba.jpg"
import tsscLogo from "../../assets/logo/tssc.png"


const Sidebar = () => {

  const {user,settings} = React.useContext(AuthContext);
  const dispatch = useAppDispatch();

  const [logoutUser, logoutResult] = useLogoutUserMutation()
  const [loginUserWithCookie, loginResult] = useLoginUserWithCookieMutation()
  const [getMe, getMeResult] = useLazyGetMeQuery()
  const {
    notifications,
    clear,
    markAllAsRead,
    markAsRead,
    unreadCount
  } = useNotificationCenter()

  const debugData = useAppSelector(state => state);

  const [tokenCookies] = useCookies(['Token']);
  const [userCookies] = useCookies(['User']);
  const [displayUserMenu, setDisplayUserMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // const toast = useRef(null);
  const btnFinancingRef2 = useRef(null);
  const btnProfileIconRef2 = useRef(null);
  const btnRef10 = useRef(null);
  const btnRef11 = useRef(null);

  const havePermission = (permission) => {
    let targetPermission;
    if (Array.isArray(permission)) targetPermission = permission
    else targetPermission = [permission]
    if (user?.permissions) {
      // console.log("User Permission",user.permissions)
      // console.log("Target Permission", targetPermission)

      let found = 0
      if (user.permissions.indexOf('view-admin-dashboard') >= 0) {
        found += 1
      }

      if (Array.isArray(user.permissions)) {
        if (targetPermission.some(r => (user.permissions.indexOf(r) >= 0))) {
          found += 1
        }
      } else {
        if (targetPermission.indexOf(user.permissions) >= 0) found += 1
      }

      return found > 0

    } else {
      console.log("permission not specified in user")
      return 0
    }
  }

  // useEffect(() => {
  //     // console.info("Topbar Use Effect",thisUser,getMeResult)
  //     if (thisUser) {
  //         // console.info("User found", user);
  //         // navigate("/");
  //         // } else if (getMeResult.status === 'uninitialized') {
  //         //     getMe();
  //         //     loginUserWithCookie();
  //         // }
  //         // console.info("Debug",user)
  //     }
  // }, [thisUser]);

  let menuArray = [];
  menuArray['policy'] = [
    {
      label: 'Dashboard',
      icon: <HiEye/>,
      visible: havePermission(["access-insured", "access-shipping"]),
      command: (event) => {
        navigate("/dashboard");
        // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
      }
    },
    {
      label: 'กรมธรรม์',
      icon: <HiOutlineDocument/>,
      visible: havePermission(["access-insured", "access-shipping"]),
      expanded: true,
      // disabled:true,
      // className: "align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1 shadow-2 lg:shadow-none",
      items: [
        {
          label: 'ออกกรมธรรม์',
          icon: <HiOutlineDocumentAdd/>,
          visible: havePermission(["access-insured"]),
          command: (event) => {
            navigate("/policy/new");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'ดูข้อมูลกรมธรรม์',
          icon: <HiOutlineDocumentSearch/>,
          visible: havePermission(["access-insured", "access-shipping"]),
          command: (event) => {
            navigate("/policy/view");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'แจ้งยกเลิกกรมธรรม์',
          icon: <HiOutlineDocumentRemove/>,
          visible: havePermission(["access-insured"]),
          command: () => {
            navigate("/policy/cancel");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'กรมธรรม์รอยกเลิก',
          icon: <HiDocumentRemove/>,
          command: () => {
            navigate("/policy/cancelRequest");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'กรมธรรม์ที่ถูกยกเลิก',
          icon: <HiDocument/>,
          command: () => {
            navigate("/policy/cancelled");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        // {
        //     label: 'แจ้งยกเลิกหนังสือสัญญา',
        //     icon: 'pi pi-search',
        //     command: () => {
        //         navigate("/policy/cancelContract");
        //         // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
        //     }
        // },
        {
          label: 'Verify',
          icon: <HiClipboardCheck/>,
          command: () => {
            navigate("/policy/verify");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'รายงานการออกกรมธรรม์',
          icon: <HiOutlineDocumentReport/>,
          visible:false,
          command: () => {
            navigate("/policy/report");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'รายงานกรมธรรม์',
          icon: <HiOutlineDocumentReport/>,
          command: () => {
            navigate("/policy/reportLocal");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        }
      ]
    },
    {
      label: 'Claim',
      icon: 'pi pi-search',
      visible: false,
      // visible: havePermission("view-admin-dashboard"),
      expanded: false,
      items: [
        {
          label: 'Register Claim',
          icon: 'pi pi-refresh',
          command: () => {
            toast.current.show({
              severity: 'success',
              summary: 'Updated',
              detail: 'Data Updated',
              life: 3000
            });
          }
        },
        {
          label: 'Verify & Confirm',
          icon: 'pi pi-times',
          command: () => {
            toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'ค่ามัดจำตู้',
          icon: 'pi pi-times',
          command: () => {
            toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        // {
        //   label: 'Report',
        //   icon: 'pi pi-times',
        //   command: () => {
        //     toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
        //   }
        // },
        // {
        //   label: 'Claim Report',
        //   icon: 'pi pi-times',
        //   command: () => {
        //     toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
        //   }
        // }
      ]
    },
    {
      label: 'ข้อมูลระบบ',
      icon: <HiCog6Tooth/>,
      visible: havePermission("access-shipping"),
      expanded: true,
      items: [
        {
          label: 'กำหนดชนิดตู้สินค้า',
          icon: <HiOutlineBriefcase/>,
          command: () => {
            navigate("/containerType");
          }
        },
        // {
        //   label: 'Report',
        //   icon: 'pi pi-times',
        //   command: () => {
        //     toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
        //   }
        // },
        // {
        //   label: 'Claim Report',
        //   icon: 'pi pi-times',
        //   command: () => {
        //     toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
        //   }
        // }
      ]
    },
    {
      label: 'Administration',
      icon: <HiCog/>,
      visible: havePermission("view-admin-dashboard"),
      expanded: true,
      items: [
        {
          label: 'User Accounts',
          icon: <HiUser/>,
          command: () => {
            navigate("/manage/user");
          }
        },
        // {
        //   label: 'User Profile',
        //   icon: 'pi pi-refresh',
        //   command: () => {
        //     navigate("/manage/profile");
        //   }
        // },
        {
          label: 'Roles',
          icon: <HiOutlineUserGroup/>,
          command: () => {
            navigate("/manage/role");
          }
        },
        {
          label: 'กำหนดอัตราค่ามัดจำ',
          icon: <HiBriefcase/>,
          command: () => {
            navigate("/manage/tariff");
          }
        },
        {
          label: 'เติมเงิน/เช็คยอด',
          icon: <HiCash/>,
          command: () => {
            navigate("/manage/balance");
          }
        },
        {
          label: 'Session List',
          icon: <HiUserGroup/>,
          command: () => {
            navigate("/manage/session");
          }
        },
        {
          label: 'Announcements',
          icon: <HiClipboardList/>,
          command: () => {
            navigate("/manage/announce");
          }
        },

        // {
        //   label: 'Report',
        //   icon: 'pi pi-times',
        //   command: () => {
        //     toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
        //   }
        // },
        // {
        //   label: 'Claim Report',
        //   icon: 'pi pi-times',
        //   command: () => {
        //     toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
        //   }
        // }
      ]
    }
  ]

  menuArray['system'] = [
    {
      label: 'กรมธรรม์',
      icon: 'pi pi-search',
      // className: "align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1 shadow-2 lg:shadow-none",
      items: [
        {
          label: 'ออกกรมธรรม์',
          icon: 'pi pi-users',
          command: (event) => {
            navigate("/customer/register");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'แจ้งยกเลิกกรมธรรม์',
          icon: 'pi pi-search',
          command: () => {
            toast("Hello")
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'แจ้งยกเลิกหนังสือสัญญา',
          icon: 'pi pi-search',
          command: () => {
            toast("Hello")
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'รายงานการออกกรมธรรม์',
          icon: 'pi pi-search',
          command: () => {
            toast("Hello")
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'รายงานการออกกรมธรรม์',
          icon: 'pi pi-search',
          command: () => {
            toast("Hello")
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        }
      ]
    },
    {
      label: 'Claim',
      icon: 'pi pi-search',
      items: [
        {
          label: 'Register Claim',
          icon: 'pi pi-refresh',
          command: () => {
            toast.current.show({
              severity: 'success',
              summary: 'Updated',
              detail: 'Data Updated',
              life: 3000
            });
          }
        },
        {
          label: 'Verify & Confirm',
          icon: 'pi pi-times',
          command: () => {
            toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'ค่ามัดจำตู้',
          icon: 'pi pi-times',
          command: () => {
            toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'Report',
          icon: 'pi pi-times',
          command: () => {
            toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'Claim Report',
          icon: 'pi pi-times',
          command: () => {
            toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        }
      ]
    }
  ]

  menuArray['customer'] = [
    {
      label: 'จัดการข้อมูลลูกค้า',
      icon: 'pi pi-search',
      // className: "align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1 shadow-2 lg:shadow-none",
      items: [
        {
          label: 'ลงทะเบียนลูกค้าใหม่',
          icon: 'pi pi-users',
          command: (event) => {
            navigate("/customer/register");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'เรียกดูข้อมูลลูกค้า',
          icon: 'pi pi-search',
          command: () => {
            toast("Hello")
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        }
      ]
    },
    {
      label: 'จัดการตารางนัด',
      icon: 'pi pi-search',
      items: [
        {
          label: 'ดูนัดหมายวันนี้',
          icon: 'pi pi-refresh',
          command: () => {
            toast.current.show({
              severity: 'success',
              summary: 'Updated',
              detail: 'Data Updated',
              life: 3000
            });
          }
        },
        {
          label: 'จองคิวล่วงหน้า',
          icon: 'pi pi-times',
          command: () => {
            toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'เลื่อนหรือยกเลิกคิว',
          icon: 'pi pi-times',
          command: () => {
            toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        }
      ]
    }
  ]
  menuArray['financing'] = [
    {
      label: 'PoS / บันทึกรายรับ',
      icon: 'pi pi-users',
      command: (event) => {
        navigate("/customer/register");
        // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
      }
    },
    {
      label: 'บันทึกรายจ่าย',
      icon: 'pi pi-search',
      command: () => {
        toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
      }
    }
  ]
  menuArray['management'] = [
    {
      label: 'จัดการร้าน',
      icon: 'pi pi-search',
      // className: "align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1 shadow-2 lg:shadow-none",
      items: [
        {
          label: 'รหัสสินค้า',
          icon: 'pi pi-users',
          command: (event) => {
            navigate("/manage/products");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'สต๊อกสินค้า',
          icon: 'pi pi-users',
          command: (event) => {
            navigate("/manage/stock");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'โปรโมชั่น',
          icon: 'pi pi-users',
          command: (event) => {
            navigate("/manage/promotion");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'ทรัพย์สิน (ภายใน)',
          icon: 'pi pi-search',
          command: () => {
            toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        }
      ]
    },
    {
      label: 'งานพนักงาน',
      icon: 'pi pi-search',
      // className: "align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1 shadow-2 lg:shadow-none",
      items: [
        {
          label: 'บัญชีผู้ใช้งาน',
          icon: 'pi pi-users',
          visible: havePermission("view-admin-dashboard"),
          command: (event) => {
            navigate("/manage/user");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'สิทธิ์การใช้งาน',
          icon: 'pi pi-users',
          visible: havePermission("view-admin-dashboard"),
          command: (event) => {
            navigate("/manage/role");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
        {
          label: 'ค่ามือ',
          icon: 'pi pi-users',
          command: (event) => {
            navigate("/customer/register");
            // toast.current.show({severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000});
          }
        },
      ],
    }
  ]
  menuArray['profilepicture'] = [
    {
      label: 'Debug',
      icon: 'pi pi-users',
      command: () => {
        console.info("Debug Store", debugData);
        console.info("User Context", user);
        console.info("Settings Context", settings);

      },
      visible: havePermission("view-admin-dashboard")
    },
    {
      label: 'ข้อมูลส่วนตัว',
      icon: 'pi pi-users',
      command: () => {
        navigate("/profile");
      }
    },
    {
      label: 'ออกจากระบบ',
      icon: 'pi pi-users',
      command: () => {
        logoutUser().then(() => {
          toast.info("Logged out");
          dispatch(authApi.util.resetApiState())
          dispatch(appApi.util.resetApiState())
          dispatch(systemApi.util.resetApiState())
          navigate("/login");
          // navigate(0);
          // console.log("Logout")
        });
      }
    },
  ]


  return (
    <>
      {/*<Toast ref={toast}></Toast>*/}


      <div className="min-h-screen flex relative lg:static surface-ground">
        <div id="app-sidebar-2"
             className="surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 surface-border select-none"
             style={{width: '280px',scrollbarGutter: "stable both-edges"}}>
          <div className="flex flex-column h-full">
            <div className="flex align-items-center px-5 flex-shrink-0" style={{height: '60px'}}
            onClick={()=>{
              console.log(location)
              if (location.pathname !== "/dashboard") {
                navigate("/dashboard")
              }
            }}>
              Container Deposit Insurance
              {/*<Avatar name={"Container Deposit Insurance"} size="48"></Avatar>*/}
              {/*<img src={tsscLogo} style={{height:"48px"}} alt="TSSC Logo" />*/}
              {/*<img src={tacbaLogo} style={{height:"48px"}} alt="TACBA Logo" />*/}
              {/*<img src={ctatLogo} style={{height:"48px"}} alt="CTAT Logo" />*/}
            </div>

            <div className="overflow-y-auto">
              <PanelMenu model={menuArray['policy']} ref={btnFinancingRef2} multiple id="policyMenu"/>
            </div>

            <div className="mt-auto mx-3">

              <hr className="mb-3 border-top-1 surface-border"/>
              {/*<Menu model={menuArray['profilepicture']} popup ref={btnProfileIconRef2}*/}
              {/*      id="profilepicture_menu"/>*/}
              <a
                className="p-ripple my-3 flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors w-full"
                onClick={(event) => setDisplayUserMenu(!displayUserMenu)}>
                <Avatar initials={() => {
                  return user?.roles[0].slice(0, 3)
                }} name={user?.roles[0] || "? ?"} size="32"/>&nbsp;
                <span className="ont-medium">{user?.name || "Unregistered"}</span>
                <Ripple/>
              </a>
              <PanelMenu model={menuArray['profilepicture']} hidden={!displayUserMenu} ref={btnProfileIconRef2}
                         id="profilepicturemenu"/>
              <div className="flex align-items-center align-center px-5 flex-shrink-0 mb-3" style={{height: '60px'}}>
                <div align={"center"} style={{width:"100%"}}>
                  <img src={tsscLogo} style={{height:"48px", marginRight:"10px"}} alt="TSSC Logo" />
                  <img src={tacbaLogo} style={{height:"48px", marginRight:"10px"}} alt="TACBA Logo" />
                  <img src={ctatLogo} style={{height:"48px"}} alt="CTAT Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="min-h-screen flex flex-column relative flex-auto " style={{maxHeight: "100vh"}}>
          <div
            className="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static"
            style={{height: '60px'}}>
            <div className="flex">
              <StyleClass nodeRef={btnRef10} selector="#app-sidebar-2" enterClassName="hidden"
                          enterActiveClassName="fadeinleft" leaveToClassName="hidden" leaveActiveClassName="fadeoutleft"
                          hideOnOutsideClick>
                <a ref={btnRef10} className="p-ripple cursor-pointer block lg:hidden text-700 mr-3">
                  <i className="pi pi-bars text-xl mt-3"></i>
                  <Ripple/>
                </a>
              </StyleClass>
              {/*<span className="p-input-icon-left">*/}
              {/*      <i className="pi pi-search"></i>*/}
              {/*      <InputText type="search" className="border-none" placeholder="Search"/>*/}
              {/*  </span>*/}
            </div>
            <StyleClass nodeRef={btnRef11} selector="@next" enterClassName="hidden" enterActiveClassName="fadein"
                        leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
              <a ref={btnRef11} className="p-ripple cursor-pointer block lg:hidden text-700">
                <i className="pi pi-ellipsis-v text-2xl"></i>
                <Ripple/>
              </a>
            </StyleClass>
            <ul className="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
    surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
              {/*  <li>*/}
              {/*    <a className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer*/}
              {/*transition-duration-150 transition-colors w-full">*/}
              {/*      <i className="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>*/}
              {/*      <span className="block lg:hidden font-medium">Inbox</span>*/}
              {/*      <Ripple/>*/}
              {/*    </a>*/}
              {/*  </li>*/}
              <li>
                <a className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full" onClick={() => {
                  logoutUser().then(() => {
                    toast.info("Logged out");
                    dispatch(authApi.util.resetApiState())
                    dispatch(appApi.util.resetApiState())
                    dispatch(systemApi.util.resetApiState())
                    navigate("/login");
                    // navigate(0);
                    // console.log("Logout")
                  });
                }
                }>
                  <i className="pi pi-sign-out text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge"></i>
                  <span className="block lg:hidden font-medium">ออกจากระบบ</span>
                  <Ripple/>
                </a>
              </li>
              <li className="border-top-1 surface-border lg:border-top-none">
                <a className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full">
                  {/*<img src="assets/images/blocks/avatars/circle/avatar-f-1.png" alt="avatar-f-1" className="mr-3 lg:mr-0" style={{ width: '32px', height: '32px' }} />*/}
                  <div className="block lg:hidden">
                    {/*<Avatar initials={()=>{return user?.roles[0].slice(0,3)}} name={user?.roles[0] || "? ?"} size="32" />&nbsp;*/}
                    {/*<span className="ont-medium">{user?.name || "Unregistered"}</span>*/}
                    <div className="text-900 font-medium">{user?.name || "Unregistered"}</div>
                    <span className="text-600 font-medium text-sm">{user?.roles[0]}</span>
                  </div>
                  <Ripple/>
                </a>
              </li>
            </ul>
          </div>
          <div className="p-5 flex flex-column flex-auto overflow-y-auto "
               style={{scrollbarGutter: "stable both-edges"}}>
            <div className="border-2 surface-border border-round surface-section surface-ground flex-auto"
                 >
              <Outlet/>
            </div>
          </div>
        </div>
      </div>


    </>
  );

}

export default Sidebar