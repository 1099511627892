export const settings = {
        APP_NAME: "CDI SERVICES",

        ROLE_SUPER_ADMIN: 'Super Admin',
        ROLE_ADMIN: 'Admin',
        ROLE_MODERATOR: 'Moderator',
        ROLE_AUTHENTICATED: 'Authenticated',

        PERMISSION_ADMIN: 'administrator',
        PERMISSION_MODERATOR: 'moderator',
        PERMISSION_GENERIC_ACCOUNTING: 'access-accounting',
        PERMISSION_GENERIC_CUSTOMER: 'access-customer',
        PERMISSION_GENERIC_STAFF: 'access-staff',
        PERMISSION_SUBMIT_VERIFICATION: 'submit-verification',
        PERMISSION_VIEW_ADMIN_DASHBOARD: 'view-admin-dashboard',
        PERMISSION_VIEW_ALL_USERS: 'view-all-users',

}
export default settings;