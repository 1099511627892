import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  settings: {},
  beneficiary: [],
};

export const appSlice = createSlice({
  initialState,
  name: 'appSlice',
  reducers: {
    clearSystemSettings: () => {
      return initialState
    },
    getBeneficiaries: (state) => {
      return state.settings
    },
    setBeneficiaries: (state, action) => {
      state.beneficiary = action.payload
    },
    setSettings: (state, action) => {
      state.settings = action.payload;
    },

    getSettings: (state) => {
      return state.settings
    },
  },
});

export default appSlice.reducer;

export const {
  clearSystemSettings,
  setBeneficiaries,
  getBeneficiaries,
  setSettings,
  getSettings,
} = appSlice.actions;
