import React, {useEffect, useRef, useState} from "react";

// import {
//     createBrowserRouter,
//     RouterProvider,
// } from "react-router-dom";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Switch,
    Outlet,
    NavLink,
    useHistory,
    useLocation, useNavigate
} from "react-router-dom";
import {useCookies} from "react-cookie";

import {InputText} from "primereact/inputtext";
import {Badge} from "primereact/badge";
import {StyleClass} from "primereact/styleclass";
import {Ripple} from "primereact/ripple";
import {Button} from 'primereact/button';


import {Helmet} from "react-helmet-async";

import {classNames} from "primereact/utils";
import PrimeReact from "primereact/api";

import {Menu} from 'primereact/menu';

import Topbar from './sidebar'
import Login from '../login/login'
import Dashboard from '../dashboard/dashboard'
import {TransitionGroup,CSSTransition} from "react-transition-group";

import "./anim.css"
import {AuthContext} from "../authProvider";

const LayoutSkeleton = () => {

    let location = useLocation();
    const navigate = useNavigate();
    const {user} = React.useContext(AuthContext);

    const [cookies] = useCookies();

    useEffect(() => {
        // console.log("Location+Cookie Change Effect",location,cookies,user)

    }, [location,cookies]);

    useEffect(() => {

        if (!user) {
            navigate("/login")
        }
        // Google Analytics
        // ga('send', 'pageview');
    }, [user]);

    const items = [
        {label: 'Add New', icon: 'pi pi-fw pi-plus'},
        {label: 'Remove', icon: 'pi pi-fw pi-minus'}
    ];

    return (<React.Fragment>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>TSSC</title>
            <link rel="canonical" href="https://www.tssc.co.th"/>
        </Helmet>

        <div className="min-h-screen flex flex-column surface-ground">
            {/*<Topbar/>*/}
            {/*<TransitionGroup>*/}
            {/*    <CSSTransition*/}
            {/*        key={location.pathname}*/}
            {/*        classNames="fade"*/}
            {/*        timeout={150}*/}
            {/*    >*/}
                    <Outlet/>
            {/*    </CSSTransition>*/}
            {/*</TransitionGroup>*/}
        </div>
    </React.Fragment>);

}

export default LayoutSkeleton