import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    settings: {},
    frontPageNotice: [],
};

export const systemSlice = createSlice({
    initialState,
    name: 'systemSlice',
    reducers: {
        clearSystemSettings: () => {
            return initialState
        },
        setSettings: (state, action) => {
            state.settings = action.payload;
        },
        getSettings: (state) => {
            return state.settings
        },
        setFrontPageNotice: (state, action) => {
            state.frontPageNotice = action.payload;
        },
        getFrontPageNotice: (state, action) => {
            return state.frontPageNotice
        },
    },
});

export default systemSlice.reducer;

export const { clearSystemSettings, setSettings, getSettings,setFrontPageNotice,getFrontPageNotice } = systemSlice.actions;
