import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {setSettings,setFrontPageNotice} from './systemSlice';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT || "";

export const systemApi = createApi({
    reducerPath: 'systemApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/system/`,
    }),
    tagTypes: ['SystemSetting','AdminUser'],
    endpoints: (builder) => ({
        getSettings: builder.query({
            query() {
                return {
                    url: 'getSettings',
                    credentials: 'include',
                };
            },
            providesTags: [{type: 'SystemSetting', id: 'LIST'}],
            transformResponse: (result) =>
                result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(setSettings(data));
                    // console.info("Loading Setting", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        editSettings: builder.mutation({
            query(data) {
                return {
                    url: 'setSettings',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['SystemSetting'],
        }),
        getLoginNotice: builder.query({
            query() {
                return {
                    url: 'getLoginNotice',
                };
            },
            providesTags: [{type: 'SystemSetting', id: 'NOTICE'}],
            transformResponse: (result) =>
              result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(setFrontPageNotice(data));
                    // console.info("Loading Setting", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        importUserDatabase: builder.mutation({
            query() {
                return {
                    url: 'importUserDatabase',
                    credentials: 'include',
                    method: 'POST'
                };
            },
            transformResponse: (result) =>
                result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setSettings(data));
                    console.info("Imported", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
            invalidatesTags: ['AdminUser'],
        }),

    }),
});

export default systemApi

export const {
    /* User */
    useGetSettingsQuery,
    useGetLoginNoticeQuery,
    useEditSettingsMutation,
    useImportUserDatabaseMutation,
} = systemApi