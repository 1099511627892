import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
// import {LoginInput} from '../../pages/login.page';
// import {RegisterInput} from '../../pages/register.page';
// import {IGenericResponse} from './types';
import {userApi} from './userApi';
import {setUser, setToken, logout} from "./userSlice";
import Cookies from 'js-cookie'
// import {useNavigate} from "react-router-dom";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT || "";

export const authApi = createApi(
        {
            reducerPath: 'authApi',
            baseQuery: fetchBaseQuery({
                baseUrl: `${BASE_URL}/auth/`,
            }),
            tagTypes: ['Auth'],
            endpoints: (builder) => ({
                registerUser: builder.mutation({
                    query(data) {
                        return {
                            url: 'register',
                            method: 'POST',
                            body: data,
                        };
                    },
                }),
                loginUser: builder.mutation({
                    query(data) {
                        return {
                            url: 'login',
                            method: 'POST',
                            body: data,
                            // credentials: 'include',
                        };
                    },
                    providesTags: ['Auth'],

                    async onQueryStarted(args, {dispatch, queryFulfilled}) {
                        try {
                            // await dispatch(userApi.endpoints.getMe.initiate(null));
                            // console.log("LoginArgs",args);
                            const { data } = await queryFulfilled;
                            // console.info("loginUser",data)
                            await dispatch(setUser(data.data));
                            // await dispatch(setToken(data.data.token));

                        } catch (error) {
                        }
                    },
                }),
                loginUserWithCookie: builder.mutation({
                    query(data) {
                        return {
                            url: 'cookie',
                            credentials: 'include',
                        };
                    },
                    providesTags: ['Auth'],
                    async onQueryStarted(args, {dispatch, queryFulfilled}) {
                        try {
                            // await dispatch(userApi.endpoints.getMe.initiate(null));
                            const { data } = await queryFulfilled;
                            // console.info("loginUser",data)
                            await dispatch(setUser(data.data));
                            // await dispatch(setToken(data.data.token));

                        } catch (error) {

                        }
                    },
                }),
                verifyEmail: builder.mutation({
                    query({verificationCode}) {
                        return {
                            url: `verifyemail/${verificationCode}`,
                            method: 'GET',
                        };
                    },
                }),
                logoutUser: builder.mutation({
                    query() {
                        return {
                            url: 'logout',
                            method: 'POST',
                            credentials: 'include',
                        };
                    },
                    invalidatesTags:['Auth'],
                    async onQueryStarted(args, {dispatch, queryFulfilled}) {
                        try {
                            // await dispatch(userApi.endpoints.getMe.initiate(null));
                            //
                            // const { data } = await queryFulfilled;
                            // // console.info("loginUser",data)
                            // await dispatch(setUser(data.data.user));
                            // await dispatch(setToken(data.data.token));
                            const result = await queryFulfilled;
                            // console.info("Logout Result",result)
                            Cookies.remove('Token');
                            Cookies.remove('User');
                            Cookies.remove('Permissions');
                            Cookies.remove('Roles');
                            Cookies.remove('Signed');

                            await dispatch(logout());


                        } catch (error) {
                        }
                    },
                }),
            }),
        }
    )
;

export const {
    useLoginUserMutation,
    useLoginUserWithCookieMutation,
    useRegisterUserMutation,
    useLogoutUserMutation,
    useVerifyEmailMutation,
} = authApi;