import React, {useRef, useState, createContext, createRef, useEffect} from "react";
import {useAppSelector} from "../app/store";
import {useCookies} from "react-cookie";
import {useLoginUserMutation, useLoginUserWithCookieMutation, useLogoutUserMutation} from "../features/auth/authApi";
import {useGetSettingsQuery, useGetLoginNoticeQuery} from "../features/system/systemApi";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";


export const AuthContext = createContext();

export const AuthProvider = ({children}) => {

  const user = useAppSelector(state => state.user)
  const system = useAppSelector(state => state.system)
  const navigate = useNavigate();
  const location = useLocation();
  const from = ((location.state)?.from.pathname) || '/';

  const [cookies] = useCookies(['Token']);
  const [loginUserWithCookie, loginUserWithCookieResult] = useLoginUserWithCookieMutation()
  const [logoutUser, logoutResult] = useLogoutUserMutation()
  const [loginUser, loginResult] = useLoginUserMutation()
  const [needLogin, setNeedLogin] = useState(false);

  const {
    data: setting = [],
    isFetching: settingFetching,
    isLoading: settingLoading,
    isSuccess: settingSuccess,
    refetch: settingRefetch
  } = useGetSettingsQuery()

  const {
    data: loginNotice = [],
    isFetching: loginNoticeFetching,
    isLoading: loginNoticeLoading,
    isSuccess: loginNoticeSuccess,
    refetch: loginNoticeRefetch
  } = useGetLoginNoticeQuery()

  useEffect(() => {
    // console.log("Auth Provider Login Check", user, cookies, loginResult, needLogin)
    if (!user.user && cookies.Token && loginResult.isUninitialized && !needLogin) {
      // console.log("Authenticate with cookies");
      setNeedLogin(true)
    }

  }, [user, cookies, loginResult])

  useEffect(() => {
    // console.info("Use Effect Trigger - loginResult",loginResult)
    if (loginResult.isSuccess) {
      // console.info("Use Effect Trigger - loginResult.isSuccess")
      toast.success("You're successfully logged in")
      navigate("/");
    } else {


    }

    if (loginResult.isError) {
      console.info("Use Effect Trigger - loginResult.isError",loginResult.error)
      if (loginResult.error.data && loginResult.error.data.error) {
        toast.warn("Login failed")
        toast.error(loginResult.error.data.error)
      } else {
        toast.warn("Login failed, Please recheck your user/password")
      }

    }
    // console.info("Use Effect Trigger - loginResult.isNotError",user,user.user,)
    if (user && user.user) {
      console.info("User found", user);
      // console.info("Navigate from login user : from", from);
      navigate("/");
    }


    // console.info("Debug",user)
  }, [loginResult]);

  useEffect(() => {
    // console.info("Use Effect Trigger - loginUserWithCookieResult")
    if (loginUserWithCookieResult.isSuccess) {
      // console.info("Use Effect Trigger - loginUserWithCookieResult.isSuccess",from)
      // console.log(location)

      if (location?.pathname.startsWith("/verify/")) return;

      toast.success("You're successfully logged in using cookie")
      switch (from) {
        case "/":
          navigate("/dashboard");
          break;
        case "/login":
          navigate("/");
          break;
        default:
          toast.info("Redirect back to " + from)
          navigate(from);

      }
    }

    if (loginUserWithCookieResult.isError) {
      // console.info("Use Effect Trigger - isError")
      // toast.warn("Login failed, Please recheck your user/password")
    }

    if (user && user.user) {
      // console.info("User found", user);
      // console.info("Navigate from login user : from", from);
      navigate("/");
    }
    // console.info("Debug",user)
  }, [loginUserWithCookieResult]);

  useEffect(() => {
    console.info("Use Effect Trigger - needLogin")
    if (needLogin) {
      loginUserWithCookie();
      setNeedLogin(false);
    }
  }, [needLogin])

  useEffect(() => {
    // console.info("Use Effect Trigger")
    if (settingSuccess) {
      // console.log("Setting Loaded",setting);
      // console.info("Use Effect Trigger - isSuccess")
      // toast.success("You're successfully logged in")
      // navigate("/");
    }

    // console.info("Debug",user)
  }, [settingSuccess]);
  const handleLogin = async (values, from) => {
    loginUser(values, from)
    // const token = await fakeAuth();
    //
    // setToken(token);
    // navigate('/dashboard');
  };

  const handleLogout = () => {
    // setToken(null);
  };

  const value = {
    user: user?.user,
    settings: system?.settings,
    frontPageNotice: system?.frontPageNotice,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };


  // console.log(system)
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;