import React, {useEffect, useRef, useState} from "react";

// import {
//     createBrowserRouter,
//     RouterProvider,
// } from "react-router-dom";

import {BrowserRouter as Router, Routes, Route, NavLink, useHistory, useLocation} from "react-router-dom";
import {BreadCrumb} from "primereact/breadcrumb";

import {InputText} from "primereact/inputtext";
import {Badge} from "primereact/badge";
import {StyleClass} from "primereact/styleclass";
import {Ripple} from "primereact/ripple";
import {Button} from 'primereact/button';


import {Helmet} from "react-helmet-async";

import {classNames} from "primereact/utils";
import PrimeReact from "primereact/api";

import {Menu} from 'primereact/menu';
import {StyledBreadcrumb} from "../../components/styledBreadcrumb";
import Settings from "../../settings";
import {
  useConfirmCertificateCancellationMutation,
  useGetCertificateListQuery,
  useGetDashboardQuery
} from "../../features/app/appApi";
import {toast} from "react-toastify";
import {DataTable} from "primereact/datatable";
import {Dropdown} from "primereact/dropdown";
import {Column} from "primereact/column";
import {DateTime} from "luxon";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {BigNumber} from "bignumber.js";
import {Dialog} from "primereact/dialog";
import FileSaver from "file-saver";
import {AuthContext} from "../authProvider";

import {HiOutlineDocumentMinus} from "react-icons/hi2";
import parse from 'html-react-parser';
import "./dashboard-override.css"

const Dashboard = () => {

  const {user, frontPageNotice} = React.useContext(AuthContext);
  const [dashboardFilter, setDashboardFilter] = useState({})
  const [dashboardData, setDashboardData] = useState({
    awaitCancel: [],
    twoDay: [],
  })
  const {
    data: dashboardTableQuery = [],
    isFetching: dashboardFetching,
    isLoading: dashboardLoading,
    isSuccess: dashboardSuccess,
    refetch: dashboardRefetch
  } = useGetDashboardQuery(dashboardFilter, {
    pollingInterval: 900000, // 900000 = 15minutes ... 1200000 = 20 minute
  })

  const [cancelCertificate, {
    data: cancelCertificateResult,
    isFetching: cancelCertificateFetching,
    isError: cancelCertificateError,
    isSuccess: cancelCertificateSuccess,
    reset: resetCancelCertificate,
  }] = useConfirmCertificateCancellationMutation()

  const defaultResult = {
    "id": null,
    "policy_number": null,
    "policy_date": null,
    "insured_id": null,
    "insurer_id": null,
    "beneficiary_id": null,
    "importer_name": null,
    "importer_address": null,
    "job_number": null,
    "bl_number": null,
    "port": null,
    "freight_forwarder": null,
    "collaborator": null,
    "collaborator_phone": null,
    "voyage_number": null,
    "ship": null,
    "etaDate": new DateTime(DateTime.now),
    "created_by": null,
    "created_date": null,
    "certificate_amount": null,
    "certificate_deposit": null,
    "certificate_premium": null,
    "last_status": null,
    "uuid": null,
    "createdAt": null,
    "updatedAt": null,
    "certificateItem": [],
    "creator": {
      "email": "", "name": "", "username": "", "status": ""
    },
    "insured": {
      "email": "", "name": "", "username": "", "status": "", "InsurerProfiles": [{
        "address": ""
      }]
    },
    "beneficiary": {
      "email": "", "name": "", "username": "", "status": ""
    }
  }
  const [resultCertificate, setResultCertificate] = useState(defaultResult)
  const [resultDialogVisible, setResultDialogVisible] = useState(false);

  let getPDF = (policyCode, mode) => {
    fetch(`/appApi/getCertificatePDF/${policyCode}/${mode}`, {
      method: 'GET', credentials: 'include'
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        if (mode === "download") {
          return FileSaver.saveAs(blob, "CERTIFICATE-" + policyCode + ".pdf")
        } else {
          let url = window.URL.createObjectURL(blob, {oneTimeOnly: true});
          let tab = window.open();
          tab.location.href = url;
          // setPdfResponse(window.URL.createObjectURL(blob));


        }

      })
      .catch((err) => {
        console.log(err);
      });
  }
  let getCertificate = (policyCode, mode) => {
    fetch(`/appApi/getCertificate/${policyCode}`, {
      method: 'GET', credentials: 'include'
    })
      .then((response) => {
        return response.json()
      })
      .then((response) => {
        // console.log(response)
        setResultCertificate({...response.data, etaDate: new DateTime(response.data.etaDate)})
        setResultDialogVisible(true)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (dashboardSuccess) {

      // setResultCertificate({...verifyCertificateResult.data, etaDate: new DateTime(verifyCertificateResult.data.etaDate)})
      //
      // // getPDF(verifyCertificateResult.data.policy_number, "view")
      setDashboardData(dashboardTableQuery)
      // console.log(dashboardTableQuery)
    }
  }, [dashboardSuccess, dashboardFetching])

  useEffect(() => {
    if (cancelCertificateSuccess) {
      setResultDialogVisible(false)
      toast.success(cancelCertificateResult.data.policy_number + " cancellation request is submitted.")
      // setResultCertificate({...saveCertificateResult.data, etaDate: new DateTime(saveCertificateResult.data.etaDate)})
      // getPDF(saveCertificateResult.data.policy_number, "view")
      // setResultDialogVisible(true)
      // console.log(saveCertificateResult)
    }
  }, [cancelCertificateSuccess, cancelCertificateFetching])

  const cag = useRef(null);
  const menu1 = useRef(null);
  const menu2 = useRef(null);
  const menu3 = useRef(null);
  const menu4 = useRef(null);

  const btnRef1 = useRef(null);
  const btnRef2 = useRef(null);
  const btnRef3 = useRef(null);
  const btnRef4 = useRef(null);
  const btnRef5 = useRef(null);
  const btnRef6 = useRef(null);
  const btnRef7 = useRef(null);
  const btnRef8 = useRef(null);
  const btnRef9 = useRef(null);
  const btnRef10 = useRef(null);
  const btnRef11 = useRef(null);
  const btnRef12 = useRef(null);
  const btnRef13 = useRef(null);

  let isLoggedIn = false;

  const items = [
    {label: 'Add New', icon: 'pi pi-fw pi-plus'},
    {label: 'Remove', icon: 'pi pi-fw pi-minus'}
  ];

  return (<React.Fragment>
    <Helmet>
      <title>Dashboard</title>
    </Helmet>
    <StyledBreadcrumb menu={[
      {label: "Dashboard", to: "/dashboard"}
    ]}/>
    <div className="p-5 flex flex-column flex-auto">
      <div className="grid">
        {(!user.BeneficiaryProfiles[0]) && <div className="col-12 lg:col-12">
          <div className="surface-card shadow-2 border-round p-4 h-full">
            <div className="flex align-items-center justify-content-between mb-3">
              <div className="text-900 font-medium text-xl">News and Announcement</div>
            </div>
            {frontPageNotice.map(record => {
              // console.log(record)
              return <li key={"fpItem-" + record.id} className="flex align-items-start mb-2">
                {/*<div>*/}
                {/*  <span className="flex align-items-center justify-content-center bg-bluegray-600"*/}
                {/*        style={{width: '52px', height: '52px', borderRadius: '10px'}}>*/}
                {/*    {record.noticeIcon*/}
                {/*      ? <i className={"text-xl text-white pi " + record.noticeIcon}></i>*/}

                {/*      : <i className={"text-xl text-white pi pi-tag"}></i>*/}
                {/*    }*/}
                {/*  </span>*/}
                {/*</div>*/}
                <div className="ml-3">
                  <span className="text-lg font-bold text-red-500 mr-2">{parse(record.header)}</span>
                  {parse(record.text)} {record.link ? <a href={record.link}>{record.linkText || "View"}</a> : ""}
                </div>
              </li>
            })}
          </div>
        </div>}

        <div className="col-12 lg:col-6">
          <div className="surface-card shadow-2 border-round p-4 h-full">
            <div className="flex align-items-center justify-content-between mb-3">
              <div className="text-900 font-medium text-xl">New Policies (Yesterday/Today)</div>
            </div>
            <DataTable value={dashboardData.twoDay} size="small"
                       className={"smallRowTable"}
                       paginator rows={10} rowsPerPageOptions={[5, 10, 20, 50]} paginatorPosition="top"
                       responsiveLayout="scroll"
            >
              <Column field="policy_number" header="Policy Number"></Column>
              <Column field="bl_number" header="B/L"></Column>
              <Column field="job_number" header="Job"></Column>
              <Column field="last_status" header="View"
                      body={(row) => {
                        return <>
                          <Button icon="pi pi-search"
                                  style={{height: "30px", width: "30px"}}

                                  className="justify-content-center" size={"small"} onClick={() => {
                            getCertificate(row.policy_number)
                          }
                          }></Button>
                          {/*<Divider/>*/}
                          {/*<Button size={"small"}>Download</Button>*/}
                        </>
                      }}>
                ></Column>
            </DataTable>
          </div>
        </div>


        {(!user.InsurerProfiles[0]) && <div className="col-12 lg:col-6">
          <div className="surface-card shadow-2 border-round p-4 h-full">
            <div className="flex align-items-center justify-content-between mb-3">
              <div className="text-900 font-medium text-xl">Awaiting Cancellation</div>
            </div>
            <DataTable value={dashboardData.awaitCancel} size="small"
                       paginator rows={10} rowsPerPageOptions={[5, 10, 20, 50]} paginatorPosition="top"
                       responsiveLayout="scroll"
            >
              <Column field="policy_number" header="Policy Number"></Column>
              <Column field="bl_number" header="B/L"></Column>
              <Column field="job_number" header="Job"></Column>
              <Column field="reason" header="Reason" body={(row) => {
                if (row.other && row.other.cancel_reason) {
                  return row.other.cancel_reason
                } else {
                  return "-"
                }
              }
              }></Column>
              <Column field="last_status" header="View"
                      body={(row) => {
                        return <>
                          <Button icon="pi pi-search"
                                  style={{height: "30px", width: "30px"}}
                                  className="justify-content-center" size={"small"} onClick={() => {
                            getCertificate(row.policy_number)
                          }
                          }></Button>
                          {/*<Divider/>*/}
                          {/*<Button size={"small"}>Download</Button>*/}
                        </>
                      }}>
                ></Column>
            </DataTable>
          </div>
        </div>}
      </div>
    </div>
    <Dialog visible={resultDialogVisible}
            onHide={() => {
              setResultDialogVisible(false)
            }}
            modal
            breakpoints={{'960px': '60vw', '640px': '50vw'}}
            className={"info-dialog"}
            header={<>
              กรมธรรม์เลขที่ {resultCertificate.policy_number}
              <span className="text-xl text-900 font-medium text-900 text-red-700" style={{float: "right"}}>
                            {resultCertificate.last_status}
                {/*{(resultCertificate.last_status === "VERIFIED") && "กรมธรรม์นี้ได้รับการยืนยันข้อมูล และถูกใช้งานแล้ว"}*/}
                {/*{(resultCertificate.last_status === "CREATED") && "กรมธรรม์นี้ถูกบันทึกในระบบแล้ว และยังไม่ได้รับการยืนยัน"}*/}
                {/*{(resultCertificate.last_status === "IMPORT") && "กรมธรรม์นี้ได้รับการ Import จากระบบเดิม"}*/}
                          </span>
            </>} draggable={false}
            resizable={false}
            footer={<div className="border-top-1 surface-border pt-3">
              <Button icon="pi pi-times"
                      onClick={() => {
                        setResultDialogVisible(false)
                      }}
                      label="กลับไปเลือกกรมธรรม์อื่น" className="p-button-text"/>
              <Button icon="pi pi-check"
                      onClick={async () => {
                        // console.log(saveCertificateResult)
                        getPDF(resultCertificate.policy_number, "download")
                        // setConfirmationDialogVisible(false)
                      }}
                      label="ดาวน์โหลดกรมธรรม์ฉบับเต็ม"/>
              <Button icon="pi pi-check"
                      onClick={async () => {
                        // console.log(saveCertificateResult)
                        getPDF(resultCertificate.policy_number, "view")
                        // setConfirmationDialogVisible(false)
                      }}
                      label="ดูกรมธรรม์ฉบับเต็ม"/>
              {resultCertificate.last_status == "REQUEST_CANCEL" && <Button icon={<HiOutlineDocumentMinus/>}
                                                                            className="p-button-danger"
                                                                            disabled={user.InsurerProfiles[0]}

                                                                            onClick={async (e) => {
                                                                              // deleteMenuRef.current.toggle(e)
                                                                              cancelCertificate({
                                                                                policy_number: resultCertificate.policy_number,
                                                                                mode: "approve"
                                                                              })
                                                                              // console.log(resultCertificate)
                                                                              // cancelCertificate({
                                                                              //   policy_number: resultCertificate.policy_number
                                                                              // })

                                                                              // getPDF(resultCertificate.policy_number, "download")
                                                                              // setConfirmationDialogVisible(false)
                                                                            }}
                                                                            label="&nbsp;ยืนยันการยกเลิกกรมธรรม์"
              />}
              {resultCertificate.last_status == "REQUEST_CANCEL" && <Button icon={<HiOutlineDocumentMinus/>}
                                                                            className="p-button-danger p-button-outlined"
                                                                            disabled={user.InsurerProfiles[0]}

                                                                            onClick={async (e) => {
                                                                              // deleteMenuRef.current.toggle(e)
                                                                              cancelCertificate({
                                                                                policy_number: resultCertificate.policy_number,
                                                                                mode: "deny"
                                                                              })
                                                                              // console.log(resultCertificate)
                                                                              // cancelCertificate({
                                                                              //   policy_number: resultCertificate.policy_number
                                                                              // })

                                                                              // getPDF(resultCertificate.policy_number, "download")
                                                                              // setConfirmationDialogVisible(false)
                                                                            }}
                                                                            label="&nbsp;ปฏิเสธการยกเลิก"
              />}
            </div>}
    >
      <div className="grid grid-nogutter  border-top-1 surface-border pt-4">
        <div className="col-12 md:col-6 p-1">
          <div className="text-500 font-medium mb-2">บริษัท</div>
          <div className="text-900">{resultCertificate.insured.name}</div>
        </div>
        <div className="col-12 md:col-3 p-1">
          <div className="text-500 font-medium mb-2">เลขที่กรมธรรม์</div>
          <div className="text-900">{resultCertificate.policy_number}</div>
        </div>
        <div className="col-12 md:col-3 p-1">
          <div className="text-500 font-medium mb-2">วันที่</div>
          <div className="text-900">{resultCertificate.policy_date}</div>
        </div>
        <div className="col-12 md:col-6 p-1">
          <div className="text-500 font-medium mb-2">ชื่อผู้นำเข้า/ผู้ส่งออกสินค้า</div>
          <div className="text-900">{resultCertificate.importer_name}</div>
        </div>
        <div className="col-12 md:col-3 p-1">
          <div className="text-500 font-medium mb-2">Job No.</div>
          <div className="text-900">{resultCertificate.job_number}</div>
        </div>
        <div className="col-12 md:col-3 p-1">
          <div className="text-500 font-medium mb-2">B/L No. หรือ Booking No.</div>
          <div className="text-900">{resultCertificate.bl_number}</div>
        </div>

        <div className="col-12 md:col-12 p-1">
          <div className="text-500 font-medium mb-2">ที่อยู่</div>
          <div className="text-900">{resultCertificate.importer_address}</div>
        </div>
        <div className="col-12 md:col-6 p-1">
          <div className="text-500 font-medium mb-2">Freight Forwarder</div>
          <div className="text-900">{resultCertificate.freight_forwarder}</div>
        </div>
        <div className="col-12 md:col-3 p-1">
          <div className="text-500 font-medium mb-2">ชื่อผู้ประสานงาน</div>
          <div className="text-900">{resultCertificate.collaborator}</div>
        </div>
        <div className="col-12 md:col-3 p-1">
          <div className="text-500 font-medium mb-2">เบอร์โทรติดต่อ</div>
          <div className="text-900">{resultCertificate.collaborator_phone}</div>
        </div>
        <div className="col-12 md:col-3 p-1">
          <div className="text-500 font-medium mb-2">Port</div>
          <div className="text-900">{resultCertificate.port}</div>
        </div>
        <div className="col-12 md:col-3 p-1">
          <div className="text-500 font-medium mb-2">ชื่อเรือ</div>
          <div className="text-900">{resultCertificate.ship}</div>
        </div>
        <div className="col-12 md:col-3 p-1">
          <div className="text-500 font-medium mb-2">Voyage No.</div>
          <div className="text-900">{resultCertificate.voyage_number}</div>
        </div>
        <div className="col-12 md:col-3 p-1">
          <div className="text-500 font-medium mb-2">ETA / ETD DATE</div>
          <div
            className="text-900">{resultCertificate.etaDate.toFormat("dd/MM/yyyy")}</div>
        </div>

        <div className="col-12 p-1">
          <div className="text-500 font-medium mb-2">บ.เรือ / ตัวแทน</div>
          <div className="text-900">{resultCertificate.beneficiary.name}</div>
        </div>
        <div className="col-12 p-1">
          <div className="text-500 font-medium mb-2">รายการตู้</div>
          <DataTable
            headerColumnGroup={<ColumnGroup>
              <Row>
                <Column header="Container Type" align="center" rowSpan={2}/>
                <Column header="20 FT" align="center" colSpan={3}/>
                <Column header="40 FT" align="center" colSpan={3}/>
                <Column header="Summary" align="center"
                        colSpan={2}/>
              </Row>
              <Row>
                <Column header="Deposit" align="right"/>
                <Column header="Premium" align="right"/>
                <Column header="Amount" align="right"/>
                <Column header="Deposit" align="right"/>
                <Column header="Premium" align="right"/>
                <Column header="Amount" align="right"/>
                <Column header="Deposit" align="right"/>
                <Column header="Premium" align="right"/>
              </Row>
            </ColumnGroup>}
            stripedRows
            value={resultCertificate.certificateItem}
            responsiveLayout="stack" showGridlines
            emptyMessage={<>ไม่ได้ระบุรายการ</>}
            size="small">
            <Column field="name" header="Container Info"
                    body={(rowData) => {
                      return <>{rowData.containerType?.container_name}</>;
                    }}></Column>

            {/*<Column field="size" header="Size"></Column>*/}
            <Column field="deposit_20" header="Deposit" style={{width: '8rem'}}
                    body={(rowData) => {
                      // console.log(rowData)
                      return <div className="text-right">{BigNumber(rowData.deposit_20).toFormat(2)}</div>;
                    }}></Column>
            <Column field="premium_20" header="Premium" style={{width: '5rem'}}
                    body={(rowData) => {
                      return <div className="text-right">{BigNumber(rowData.premium_20).toFormat(2)}</div>;
                    }}></Column>
            <Column field="amount_20" header="Amount"
                    headerStyle={{textAlign: 'right'}}
                    style={{
                      width: '9rem', backgroundColor: "rgba(155,213,231,0.32)", color: "#2b2ba2"
                    }}
                    body={(rowData) => {
                      // style={{background:rowData.size == "40ft" ? "rgba(241,121,121,0.26)" : "rgba(138,138,210,0.27)"}}
                      return <div className="text-right">{BigNumber(rowData.amount_20).toFormat(0)}</div>;
                    }}
            ></Column>
            <Column field="deposit_40" header="Deposit" style={{width: '8rem'}}
                    body={(rowData) => {
                      return <div className="text-right">{BigNumber(rowData.deposit_40).toFormat(2)}</div>;
                    }}></Column>
            <Column field="premium_40" header="Premium" style={{width: '5rem'}}
                    body={(rowData) => {
                      return <div className="text-right">{BigNumber(rowData.premium_40).toFormat(2)}</div>;
                    }}></Column>
            <Column field="amount_40" header="Amount"
                    headerStyle={{textAlign: 'right'}}
                    style={{
                      width: '9rem', backgroundColor: "rgba(255,160,130,0.2)", color: "#2b2ba2"
                    }}
                    body={(rowData) => {
                      // style={{background:rowData.size == "40ft" ? "rgba(241,121,121,0.26)" : "rgba(138,138,210,0.27)"}}
                      return <div className="text-right">{BigNumber(rowData.amount_40).toFormat(0)}</div>;
                    }}
            ></Column>
            <Column field="totalDeposit" header="Total Deposit"
                    style={{width: '9rem'}}
                    body={(rowData) => {
                      return <div className="text-right">{BigNumber(rowData.totalDeposit).toFormat(2)}</div>;
                    }}></Column>
            <Column field="totalPremium" header="Total Premium"
                    style={{width: '9rem'}}
                    body={(rowData) => {
                      return <div className="text-right">{BigNumber(rowData.totalPremium).toFormat(2)}</div>;
                    }}></Column>
          </DataTable>
        </div>
        <div className="field col-10 md:col-10 text-right">
                                        <span
                                          className="text-xl text-600 font-medium text-900 mr-2">Total Deposit</span>
        </div>
        <div className="field col-2 md:col-2 text-right">
                            <span className="text-xl text-900 font-medium text-900">
                                {BigNumber(resultCertificate.certificate_deposit).toFormat(2)}</span>
        </div>
        <div className="field col-10 md:col-10 text-right">
                                        <span
                                          className="text-xl text-600 font-medium text-900 mr-2">Total Premium</span>
        </div>
        <div className="field col-2 md:col-2 text-right">
                            <span className="text-xl text-900 font-medium text-900">
                                {BigNumber(resultCertificate.certificate_premium).toFormat(2)}</span>
        </div>

      </div>
    </Dialog>
  </React.Fragment>);

}

export default Dashboard