import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    users: null,
    roles: null,
};

export const adminSlice = createSlice({
    initialState,
    name: 'adminSlice',
    reducers: {
        clearAdminData: () => {
            return initialState
        },
        setUsers: (state, action) => {
            state.users = action.payload.users;
        },
        getUsers: (state) => {
            return state.users
        },
        setRoles: (state, action) => {
            // console.info("setToken",action.payload);
            state.roles = action.payload.roles;
        },
        getRoles: (state) => {
            return state.roles
        }
    },
});

export default adminSlice.reducer;

export const { clearAdminData, setUsers, getUsers, setRoles, getRoles } = adminSlice.actions;
