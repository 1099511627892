import React from 'react';
import {BrowserRouter as Router, Routes, Switch, Route, NavLink, useNavigate, useLocation} from "react-router-dom";
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {store} from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

const DebugRouter = ({ children }) => {
    const { location } = useNavigate()
    if (process.env.NODE_ENV === 'development') {
        console.info(
            `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
        )
    }

    return children
}


root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HelmetProvider>
                <Router>
                    <Routes>
                        <Route path="*" element={<App/>}/>
                    </Routes>
                </Router>
            </HelmetProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
