import React, {useRef, useState, useEffect} from "react";
import {Route, NavLink, useHistory, useLocation, useNavigate} from "react-router-dom";
import {useMediaQuery} from 'react-responsive'

import {InputText} from "primereact/inputtext";
import {Badge} from "primereact/badge";
import {StyleClass} from "primereact/styleclass";
import {Ripple} from "primereact/ripple";
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Divider} from 'primereact/divider';


import {Form, Field} from "react-final-form";

import {Helmet} from "react-helmet-async";

import {useLoginUserMutation} from '../../features/auth/authApi';
import {getUser} from '../../features/auth/userSlice';
import {useAppSelector} from "../../app/store";

import {toast} from 'react-toastify';
import {AuthContext} from "../authProvider";
import {Dropdown} from "primereact/dropdown";
import {RadioButton} from "primereact/radiobutton";

import "./style-override.css"
import tsscLogo from "../../assets/logo/tssc.png";
import tacbaLogo from "../../assets/logo/tacba.jpg";
import ctatLogo from "../../assets/logo/ctat.jpg";
import lineLogo from "../../assets/logo/lineit_display_btn_03.png";
import parse from "html-react-parser";
import {Password} from "primereact/password";


const LoginPage = () => {
  // const initialForm = {email: '99999', password: "test123", remember: "8"}
  const initialForm = {email: '', password: "", remember: "8"}
  const [loginUser, loginResult] = useLoginUserMutation()
  // const user = useAppSelector(state => state.user)

  const navigate = useNavigate();
  const location = useLocation();
  const from = ((location.state)?.from.pathname) || '/';
  const {user, onLogin, settings, frontPageNotice} = React.useContext(AuthContext);


  const isLarge = useMediaQuery({query: '(min-width: 992px)'})

  const [checked3, setChecked3] = useState(false);

  const [displayMode, setDisplayMode] = useState("login");

  // const demoUser = [
  //
  //   {username: "99999", password: "test123"},
  //   {username: "S099", password: "s099"},
  //   {username: "S002", password: "HEUNG-A2"},
  //
  //   {username: 'admin@natawut.ch', password: "Admin#123456"},
  //   {username: "super_admin@natawut.ch", password: "Pass#123456"},
  //   {username: "test_admin@natawut.ch", password: "Pass#123456"},
  //   // {username: "test_moderator@natawut.ch", password: "Pass#123456"},
  //   // {username: "test_staff@natawut.ch", password: "Pass#123456"},
  //   // {username: "test_accountant@natawut.ch", password: "Pass#123456"},
  //   // {username: "test_verified@natawut.ch", password: "Pass#123456"},
  //   // {username: "test_need_verified@natawut.ch", password: "Pass#123456"},
  //   {username: "test_registered@natawut.ch", password: "Pass#123456"},
  //   {username: "test_insured@natawut.ch", password: "Pass#123456"},
  //   {username: "test_insurance@natawut.ch", password: "Pass#123456"},
  //   {username: "test_beneficiary@natawut.ch", password: "Pass#123456"},
  // ];

  const items = [
    {label: 'Add New', icon: 'pi pi-fw pi-plus'},
    {label: 'Remove', icon: 'pi pi-fw pi-minus'}
  ];

  const onSubmit = async values => {
    // window.alert(JSON.stringify(values, 0, 2))
    onLogin(values, from)
    // loginUser(values)
  }

  return (<>
    <Helmet>
      <meta charSet="utf-8"/>
      <title>Login Required</title>
      <link rel="canonical" href="https://www.tssc.co.th"/>
    </Helmet>
    <div style={{
      // background: 'url("assets/images/blocks/signin/signin-2.jpg") no-repeat',
      background: 'url("assets/images/bg-container-2.jpg") no-repeat',
      minHeight: "100vh",
      backgroundSize: 'cover'
    }}
         className="px-4 py-8 md:px-6 lg:px-8">
      <div className="flex flex-wrap" style={{minHeight: "80vh"}}>

        <Form
          onSubmit={onSubmit}
          initialValues={{...initialForm}}
          mutators={{
            setValue: ([field, value], state, {changeValue}) => {
              changeValue(state, field, () => value)
            }
          }}

          render={({handleSubmit, form, submitting, pristine, values}) => (
            <>
              <div className="w-full lg:w-6 p-4 lg:p-7 surface-card"
                   style={{backgroundColor: 'rgba(255,255,255,.7)', order: isLarge ? 1 : 2}}
              >
                <form onSubmit={handleSubmit}>
                  {(settings.maintenance == true) &&
                    <div className="text-900 text-2xl font-medium mb-6 text-red-600">ระบบอยู่ระหว่างการปรับปรุง</div>}
                  <div className="text-900 text-2xl font-medium mb-6">Login</div>
                  <div className="grid">
                    <div className="w-6 p-2">
                      <label htmlFor="email3" className="block text-900 font-medium mb-2">Username / Email</label>
                      <Field name="email">
                        {props => (
                          <InputText
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            id="email" type="text" className="w-full mb-4"/>
                        )}
                      </Field>
                    </div>
                    <div className="w-6 p-2">
                      <label htmlFor="password" className="block text-900 font-medium mb-2"
                      >Password</label>
                      <Field name="password">
                        {props => (
                          <Password
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            toggleMask
                            feedback={false}
                            id="password" type="password" className="w-full mb-4"
                            inputClassName="w-full"/>
                        )}
                      </Field>

                    </div>
                  </div>

                  {/*<InputText id="email3" type="text" className="w-full mb-4"/>*/}

                  {/*<label htmlFor="quickUser" className="block text-900 font-medium mb-2">Quick User</label>*/}
                  {/*<Dropdown name="quickUser" size="small"*/}
                  {/*          className="w-full mb-4"*/}
                  {/*          options={demoUser} optionLabel="username"*/}
                  {/*          onChange={(e) => {*/}
                  {/*            // console.log(e.value)*/}
                  {/*            form.mutators.setValue("email", e.value.username);*/}
                  {/*            form.mutators.setValue("password", e.value.password);*/}
                  {/*          }}/>*/}
                  <Field name="remember">
                    {props => (
                      <>
                        <label htmlFor="remember" className="block text-900 font-medium mb-2">Stay login for</label>
                        <Dropdown name="remember" size="small"
                                  style={{height: "40px"}}
                                  className="w-full mb-6 login-small-height"
                                  options={[
                                    {name: "1 Hour", value: "1"},
                                    {name: "8 Hour", value: "8"},
                                    {name: "End of day", value: "EOD"},
                                    {name: "End of week", value: "EOW"},
                                  ]}
                                  optionLabel="name"
                                  value={props.input.value}
                                  onChange={(e) => {
                                    // console.log(e.value)
                                    form.mutators.setValue("remember", e.value);
                                    // form.mutators.setValue("password", e.value.password);
                                  }}/>


                      </>
                      // <Checkbox id="remember" className="mr-2"
                      //           name={props.input.name}
                      //           checked={props.input.value}
                      //           onChange={(e) => props.input.onChange(e.checked)}
                      // />
                    )}
                  </Field>
                  {/*<label htmlFor="remember">Remember me</label>*/}

                  <Button label="Login" icon="pi pi-user" className="w-full" type="submit"
                          disabled={submitting}/>

                  <div className="text-900 text-xl mt-6 font-medium text-center">ช่องทางการติดต่อเพื่อสอบถามข้อมูลและติดตามรายละเอียดต่างๆ
                    ของโครงการ
                    <p/><a href="https://page.line.me/873qmdkk" target="_blank"><img src={lineLogo} height={35} className="mt-2"/></a>
                  </div>
                  {/*<Divider align="center" className="my-6">*/}
                  {/*    <span className="text-600 font-normal text-sm">OR</span>*/}
                  {/*</Divider>*/}

                  {/*<Button label="Sign In with GitHub" icon="pi pi-github"*/}
                  {/*        className="w-full p-button-secondary"/>*/}

                  {/*<div>&nbsp;</div>*/}
                  {/*<Button label="Test" icon="pi pi-github"*/}
                  {/*        className="p-button-link"*/}
                  {/*        onClick={()=>{console.info(getUser())}}*/}
                  {/*/>*/}

                  {/*<div className="mt-6 text-center text-600">*/}
                  {/*    Don't have an account?<br/>*/}
                  {/*    <a tabIndex="0" className="font-medium text-blue-500"*/}
                  {/*       onClick={() => {*/}
                  {/*           navigate("/register")*/}
                  {/*       }}>Sign up</a>*/}
                  {/*</div>*/}
                </form>
                <div className={"w-full"}>
                  {frontPageNotice.map(record => {
                    if (record.status == "highlight") {
                      return <div className="w-full text-center">
                        <span className="text-lg font-bold text-red-500 text-center">{parse(record.header)}</span>
                        <p className="mt-1 mb-0 text-black-alpha-90 line-height-3">{parse(record.text)} {record.link ?
                          <a href={record.link}>{record.linkText || "View"}</a> : ""}</p>
                      </div>
                    } else {
                      return <></>
                    }
                  })
                  }
                </div>
              </div>
              <div className="w-full lg:w-6 p-4 lg:p-7"
                   style={{backgroundColor: 'rgba(255,255,255,.7)', order: isLarge ? 2 : 1}}>
                <div align={"center"} style={{width: "100%"}}>
                  <img src={tsscLogo} style={{height: "70px", marginRight: "10px"}} alt="TSSC Logo"/>
                  <img src={tacbaLogo} style={{height: "70px", marginRight: "10px"}} alt="TACBA Logo"/>
                  <img src={ctatLogo} style={{height: "70px"}} alt="CTAT Logo"/>
                </div>
                <div className="text-3xl text-blue-900 font-500 mt-3 mb-1 text-center">Container Deposit Insurance
                  (CDI)
                </div>
                <div
                  className="text-xl text-black-alpha-90 font-500 mb-3 text-center">โครงการกรมธรรม์ประกันภัยค้ำประกันค่ามัดจำตู้
                </div>
                <div
                  className="text-xl text-red-700 font-700 mb-3 text-left">ประกาศและข่าวสาร
                </div>
                <ol className="list-decimal p-0 m-0">
                  {frontPageNotice.map(record => {
                    if (record.status == "active") {
                      return <li key={"frontNotice" + record.id} className="flex align-items-start mb-2">
                        {/*<div>*/}
                        {/*  <span className="flex align-items-center justify-content-center bg-bluegray-600"*/}
                        {/*        style={{width: '52px', height: '52px', borderRadius: '10px'}}>*/}
                        {/*    {record.noticeIcon*/}
                        {/*      ? <i className={"text-xl text-white pi " + record.noticeIcon}></i>*/}

                        {/*      : <i className={"text-xl text-white pi pi-tag"}></i>*/}
                        {/*    }*/}
                        {/*  </span>*/}
                        {/*</div>*/}
                        <div className="ml-3">

                          <span className="text-lg font-bold text-red-500">{parse(record.header)}</span>
                          <p className="mt-1 mb-0 text-black-alpha-90 line-height-3">{parse(record.text)} {record.link ?
                            <a href={record.link}>{record.linkText || "View"}</a> : ""}</p>
                        </div>
                      </li>
                    } else {
                      return <></>
                    }
                  })
                  }
                  {/*<li className="flex align-items-start">*/}
                  {/*  <div>*/}
                  {/*                          <span*/}
                  {/*                            className="flex align-items-center justify-content-center bg-purple-400"*/}
                  {/*                            style={{width: '38px', height: '38px', borderRadius: '10px'}}>*/}
                  {/*                              <i className="text-xl text-white pi pi-globe"></i>*/}
                  {/*                          </span>*/}
                  {/*  </div>*/}
                  {/*  <div className="ml-3">*/}
                  {/*    <span className="font-medium text-black-alpha-90">Cloud Backups Inbox</span>*/}
                  {/*    <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">Demo context*/}
                  {/*      4</p>*/}
                  {/*  </div>*/}
                  {/*</li>*/}
                </ol>
              </div>
            </>

          )}
        />


      </div>
    </div>

  </>);

}

export default LoginPage