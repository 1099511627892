import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
    user: null,
    userToken: null,
};

export const userSlice = createSlice({
    initialState,
    name: 'userSlice',
    reducers: {
        logout: () => {
            return initialState
        },
        setUser: (state, action) => {
            if (action.payload) {
                state.user = action.payload.user;
                state.userToken = action.payload.token;
            }

        },
        getUser: (state) => {
            return state.user
        },
        setToken: (state, action) => {
            // console.info("setToken",action.payload);
            if (action.payload) {
                state.userToken = action.payload.token;
            }
        },
        getToken: (state) => {
            return state.userToken
        }
    },
});

export default userSlice.reducer;

export const {logout, setUser, getUser, setToken, getToken} = userSlice.actions;
