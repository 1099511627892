import React, {useRef, useState} from "react";

// import {
//     createBrowserRouter,
//     RouterProvider,
// } from "react-router-dom";

import {BrowserRouter as Router, Routes, Route, NavLink, useHistory, useLocation} from "react-router-dom";

import {Badge} from "primereact/badge";
import {StyleClass} from "primereact/styleclass";
import {Ripple} from "primereact/ripple";
import {Button} from 'primereact/button';
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";

import {Helmet} from "react-helmet-async";

import {classNames} from "primereact/utils";
import PrimeReact from "primereact/api";

import {Menu} from 'primereact/menu';
import {StyledBreadcrumb} from "../../components/styledBreadcrumb";


const SubmitVerification = () => {

    const [check2, setCheck2] = useState(false);

    const items = [
        {label: 'Add New', icon: 'pi pi-fw pi-plus'},
        {label: 'Remove', icon: 'pi pi-fw pi-minus'}
    ];

    return (<React.Fragment>

        <div className="surface-ground px-4 py-4 md:px-6 lg:px-8">
            <div className="text-900 font-medium text-900 text-xl mb-3">Submit Validation Document</div>
            <div className="surface-card p-4 shadow-2 border-round p-fluid">
                <div className="grid formgrid p-fluid">
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="company_name" className="font-medium text-900">Company Name</label>
                        <InputText id="company_name" type="text" />
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="invoice_id" className="font-medium text-900">Invoice Id</label>
                        <InputText id="invoice_id" type="text" />
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="customer_name" className="font-medium text-900">Customer Name</label>
                        <InputText id="customer_name" type="text" />
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="customer_email" className="font-medium text-900">Customer Email</label>
                        <InputText id="customer_email" type="text" />
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="invoice_date" className="font-medium text-900">Invoice Date</label>
                        <Calendar id="invoice_date" showIcon />
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="due_date" className="font-medium text-900">Due Date</label>
                        <Calendar id="due_date" showIcon />
                    </div>
                    <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                    <div className="field mb-4 col-12 md:col-6">
                        <label htmlFor="item" className="font-medium text-900">Item</label>
                        <InputText id="item1" type="text" />
                    </div>
                    <div className="field mb-4 col-6 md:col-3">
                        <label htmlFor="item" className="font-medium text-900">Quantity</label>
                        <InputText id="item2" type="text" />
                    </div>
                    <div className="field mb-4 col-6 md:col-3">
                        <label htmlFor="item" className="font-medium text-900">Price</label>
                        <div className="flex align-content-center">
                            <InputNumber mode="currency" currency="USD" id="item3" type="text" className="flex-1" />
                            <Button icon="pi pi-trash" className="p-button-danger p-button-text ml-2" />
                        </div>
                    </div>
                    <div className="field mb-4 col-12 md:col-6">
                        <Button icon="pi pi-plus" label="Add Item" className="p-button-secondary p-button-outlined w-auto" />
                    </div>
                    <div className="field mb-4 col-12 md:col-6 text-right">
                        <span className="text-xl text-600 font-medium text-900 mr-2">Total</span>
                        <span className="text-xl text-900 font-medium text-900">$59</span>
                    </div>
                    <div className="field mb-4 col-12 flex align-items-center">
                        <Checkbox checked={check2} onChange={(e) => setCheck2(e.checked)} />
                        <span className="ml-2 text-900">Taxable (VAT 18%)</span>
                    </div>
                    <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                    <div className="field mb-4 col-12">
                        <label htmlFor="notes" className="font-medium text-900">Notes</label>
                        <InputTextarea id="notes" autoResize rows={5} />
                    </div>
                    <div className="surface-border border-top-1 opacity-50 mb-4 col-12"></div>
                </div>
                <Button label="Create Invoice" icon="pi pi-file" className="w-auto" />
            </div>
        </div>
    </React.Fragment>);

}

export default SubmitVerification