import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {setUsers, setRoles} from './adminSlice';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT || "";

export const adminApi = createApi({
    reducerPath: 'adminApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/admin/`,
    }),
    tagTypes: ['AdminUser',"AdminRole","AdminRoleWithPermission","AdminTariff","AdminSession","AdminAnnounce"],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query(args) {
                // const queryParams = args ? Object.keys(args).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(args[k])).join('&') : '';
                // console.log(queryParams);
                return {
                    // url: `users/fetch?offset=${args.first}&perPage=${args.rows}`,
                    url: `users/fetch`,
                    credentials: 'include',
                    method:"post",
                    body:args
                };
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.rows.map(({id}) => ({type: 'AdminUser', id})),
                        {type: 'AdminUser', id: 'LIST'},
                    ]
                    : [{type: 'AdminUser', id: 'LIST'}],
            transformResponse: (result) =>
                result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    // console.log("queryArg",args)
                    const {data} = await queryFulfilled;
                    // dispatch(setUsers(data.users));
                    // console.info("Loading Users", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        getUsersByPage: builder.query({
            query(data) {
                return {
                    url: 'users/fetchPage',
                    credentials: 'include',
                    method: 'GET',
                    body: data,
                };
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'AdminUser', id})),
                        {type: 'AdminUser', id: 'LIST'},
                    ]
                    : [{type: 'AdminUser', id: 'LIST'}],
            transformResponse: (result) =>
                result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setUsers(data.users));
                    // console.info("Loading Users", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),

        createUser: builder.mutation({
            query(data) {
                return {
                    url: 'users/create',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminUser'],
        }),
        editUser: builder.mutation({
            query(data) {
                return {
                    url: 'users/edit',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminUser'],
        }),
        editStatusUser: builder.mutation({
            query(data) {
                return {
                    url: 'users/editStatus',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminUser'],
        }),
        getUserBalance: builder.query({
            query(args) {
                // const queryParams = args ? Object.keys(args).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(args[k])).join('&') : '';
                // console.log(queryParams);
                return {
                    // url: `users/fetch?offset=${args.first}&perPage=${args.rows}`,
                    url: `usersBalance/fetch`,
                    credentials: 'include',
                    method:"post",
                    body:args
                };
            },
            providesTags: [{type: 'AdminBalance', id: 'LIST'}],
            transformResponse: (result) =>
              result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    // console.log("queryArg",args)
                    const {data} = await queryFulfilled;
                    // dispatch(setUsers(data.users));
                    // console.info("Loading Users", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        editUserBalance: builder.mutation({
            query(data) {
                return {
                    url: 'usersBalance/edit',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminBalance'],
        }),
        createRandomUser: builder.mutation({
            query(data) {
                return {
                    url: 'users/createRandom',
                    credentials: 'include',
                    method: 'POST',
                };
            },
            invalidatesTags: ['AdminUser'],
        }),
        deleteUser: builder.mutation({
            query(data) {
                return {
                    url: 'users/delete',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminUser'],
        }),

        getRoles: builder.query({
            query() {
                return {
                    url: 'roles/fetch',
                    credentials: 'include',
                };
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'AdminRole', id})),
                        {type: 'AdminRole', id: 'LIST'},
                    ]
                    : [{type: 'AdminRole', id: 'LIST'}],
            transformResponse: (result) =>
                result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setUsers(data.users));
                    // console.info("Loading Roles", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        getRolesWithPermissions: builder.query({
            query() {
                return {
                    url: 'rolesWithPermissions/fetch',
                    credentials: 'include',
                };
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'AdminRoleWithPermission', id})),
                        {type: 'AdminRoleWithPermission', id: 'LIST'},
                    ]
                    : [{type: 'AdminRoleWithPermission', id: 'LIST'}],
            transformResponse: (result) =>
                result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setUsers(data.users));
                    // console.info("Loading Roles With Permission", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        getPermissions: builder.query({
            query() {
                return {
                    url: 'permissions/fetch',
                    credentials: 'include',
                };
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}) => ({type: 'AdminPermission', id})),
                        {type: 'AdminPermission', id: 'LIST'},
                    ]
                    : [{type: 'AdminPermission', id: 'LIST'}],
            transformResponse: (result) =>
                result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setUsers(data.users));
                    // console.info("Loading Roles", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        getTariff: builder.query({
            query() {
                return {
                    url: 'tariff/fetch',
                    credentials: 'include',
                };
            },
            providesTags: (result) =>
              result
                ? [
                    ...result.map(({id}) => ({type: 'AdminTariff', id})),
                    {type: 'AdminTariff', id: 'LIST'},
                ]
                : [{type: 'AdminTariff', id: 'LIST'}],
            transformResponse: (result) =>
              result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setUsers(data.users));
                    // console.info("Loading Roles", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        editTariff: builder.mutation({
            query(data) {
                return {
                    url: 'tariff/edit',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminTariff'],
        }),
        createRole: builder.mutation({
            query(data) {
                return {
                    url: 'roles/create',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminRoleWithPermission'],
        }),
        editRole: builder.mutation({
            query(data) {
                return {
                    url: 'roles/edit',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminRoleWithPermission'],
        }),
        deleteRole: builder.mutation({
            query(data) {
                return {
                    url: 'roles/delete',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminRoleWithPermission'],
        }),

        getProfiles: builder.query({
            query(args) {
                // const queryParams = args ? Object.keys(args).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(args[k])).join('&') : '';
                // console.log(queryParams);
                return {
                    // url: `users/fetch?offset=${args.first}&perPage=${args.rows}`,
                    url: `profiles/fetch`,
                    credentials: 'include',
                    method:"post",
                    body:args
                };
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.rows.map(({id}) => ({type: 'AdminUser', id})),
                        {type: 'AdminUser', id: 'LIST'},
                    ]
                    : [{type: 'AdminUser', id: 'LIST'}],
            transformResponse: (result) =>
                result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    // console.log("queryArg",args)
                    const {data} = await queryFulfilled;
                    // dispatch(setUsers(data.users));
                    // console.info("Loading Users", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        getSession: builder.query({
            query(args) {
                return {
                    url: 'session/fetch',
                    credentials: 'include',
                    method:"post",
                    body:args
                };
            },
            providesTags: (result) => {
                // console.log(result)
                let sessionList = result.rows
                let tags = [...sessionList.map(({id}) => ({type: 'AdminSession', id}))]
                // console.log(tags)
                return sessionList
                  ? [
                      ...sessionList.map(({id}) => ({type: 'AdminSession', id})),
                      {type: 'AdminSession', id: 'LIST'},
                  ]
                  : [{type: 'AdminSession', id: 'LIST'}]
            },
            transformResponse: (result) =>
              result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setUsers(data.users));
                    // console.info("Loading Roles", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        deleteSession: builder.mutation({
            query(data) {
                return {
                    url: 'session/cleanUp',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['AdminSession'],
        }),
        getAnnouncement: builder.query({
            query(data) {
                return {
                    url: 'announcement/fetch',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            providesTags: (result) =>
              result
                ? [
                    ...result.map(({id}) => ({type: 'AdminAnnounce', id})),
                    {type: 'AdminAnnounce', id: 'LIST'},
                ]
                : [{type: 'AdminAnnounce', id: 'LIST'}],
            transformResponse: (result) =>
              result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setUsers(data.users));
                    // console.info("Loading Roles", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
    }),
});

export default adminApi

export const {
    /* User */
    useGetUsersQuery,
    useGetUsersByPageQuery,
    useCreateUserMutation,
    useEditUserMutation,
    useEditStatusUserMutation,
    useDeleteUserMutation,
    useCreateRandomUserMutation,
    useGetUserBalanceQuery,
    useEditUserBalanceMutation,
    /* Role */
    useGetRolesQuery,
    useGetRolesWithPermissionsQuery,
    useCreateRoleMutation,
    useEditRoleMutation,
    useDeleteRoleMutation,
    /* Permission */
    useGetPermissionsQuery,
    /* Profile */
    useGetProfilesQuery,
    useGetTariffQuery,
    /* Tariff */
    useEditTariffMutation,
    useGetAnnouncementQuery,
    useGetSessionQuery,
    useDeleteSessionMutation,

} = adminApi