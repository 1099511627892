import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {setUser, setToken, getToken, logout} from './userSlice';
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT || "";

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/users/`,
    }),
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getMe: builder.query({
            query() {
                return {
                    url: 'me',
                    credentials: 'include',
                };
            },
            transformErrorResponse: (response, meta, arg) => {
                console.log(response.status);
                return response
            },
            // transformResponse: (result) =>
            //   result.data.user,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    console.info("GetMe", data)
                    dispatch(setUser(data.user));
                    dispatch(setToken(data.token));
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        getFullProfile: builder.query({
            query() {
                return {
                    url: 'getFullProfile',
                    credentials: 'include',
                };
            },
            // transformResponse: (result) =>
            //   result.data.user,
            providesTags: ['User'],
            transformErrorResponse: (response, meta, arg) => {
                console.log(response.status);
                return response
            },
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    return data;
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        updateOwnProfile: builder.mutation({
            query(data) {
                return {
                    url: 'updateOwnProfile',
                    method: 'POST',
                    credentials: 'include',
                    body: data,
                };
            },
            invalidatesTags:['User'],
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const result = await queryFulfilled;
                } catch (error) {
                }
            },
        }),
        deleteToken: builder.mutation({
            query(data) {
                return {
                    url: 'deleteToken',
                    method: 'POST',
                    credentials: 'include',
                    body: data,
                };
            },
            invalidatesTags:['User'],
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const result = await queryFulfilled;
                } catch (error) {
                }
            },
        }),
    }),
});

export default userApi
export const {useGetMeQuery, useLazyGetMeQuery, useGetFullProfileQuery, useDeleteTokenMutation, useUpdateOwnProfileMutation} = userApi