import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {useNavigate} from "react-router-dom";
// import { fetchCount } from './counterAPI';


const initialState = {
  user: {
    id: null,
    name: null,
    email: null,
    role: null,
    _id: null,
    createdAt: null,
    updatedAt: null,
    __v: null,
  },
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   'counter/fetchCount',
//   async (amount) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: () => {
      return initialState
    },
    setUser: (state, action) => {
      console.info("setting user")
      state.user = action.payload;
    },
  },
});


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state) => state.counter.value;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default authSlice.reducer;
export const { logout, setUser } = authSlice.actions;
