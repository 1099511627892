import {configureStore, MiddlewareAPI, isRejectedWithValue} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import counterReducer from '../features/counter/counterSlice';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/auth/userSlice';
import adminReducer from '../features/admin/adminSlice';
import systemReducer from '../features/system/systemSlice';
import appReducer from '../features/app/appSlice';

import {authApi} from '../features/auth/authApi';
import {userApi} from "../features/auth/userApi";
import {adminApi} from "../features/admin/adminApi";
import {systemApi} from "../features/system/systemApi";
import {appApi} from "../features/app/appApi";
import {createLogger} from 'redux-logger'


const devMode = false;

const logger = createLogger({
    // ...options
    // predicate: (getState, action) => console.info(action.type),
    diff: false,
    collapse: true,
    level: {
        prevState: false,
        nextState: false,
    }
});


export const rtkQueryErrorLogger = (api) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    let ignoredPath = ["/login"]
    if (isRejectedWithValue(action)) {
        console.warn('We got a rejected action!', action);
        console.log(window)
        if (action.payload.status === 401) {
            if (window.location && (ignoredPath.indexOf(window.location.pathname) < 0)) {
                window.location.href = '/login';
            }
        }
        // toast.warn({ title: 'Async error!', message: action.error.data.message });
    }
    return next(action);
};


const middleware = [systemApi.middleware, authApi.middleware, userApi.middleware, adminApi.middleware, appApi.middleware, rtkQueryErrorLogger]
const devModeMiddleware = [logger, ...middleware]
const prodModeMiddleware = [...middleware]


export const store = configureStore({
    reducer: {
        [systemApi.reducerPath]: systemApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [adminApi.reducerPath]: adminApi.reducer,
        [appApi.reducerPath]: appApi.reducer,
        system: systemReducer,
        user: userReducer,
        auth: authReducer,
        admin: adminReducer,
        app:appReducer,
    },
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({}).concat(devMode ? devModeMiddleware : prodModeMiddleware),
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;