import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {setBeneficiaries,setSettings,setFrontPageNotice} from './appSlice';
import {setUser} from "../auth/userSlice";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT || "";

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/appApi/`,
    }),
    tagTypes: ['app','certificate','certificatedata','app_beneficiary','app_insured','app_attachment'],
    endpoints: (builder) => ({
        getDashboard: builder.query({
            query() {
                return {
                    url: 'getDashboard',
                    credentials: 'include',
                };
            },
            providesTags: [{type: 'certificate', id: 'DASHBOARD'}],
            transformResponse: (result) =>
              result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setBeneficiaries(data));
                    // console.info("Loading Setting", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        getBeneficiary: builder.query({
            query() {
                return {
                    url: 'getBeneficiary',
                    credentials: 'include',
                };
            },
            providesTags: [{type: 'app_beneficiary', id: 'LIST'}],
            transformResponse: (result) =>
                result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(setBeneficiaries(data));
                    // console.info("Loading Setting", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        getBeneficiaryContainer: builder.query({
            query(data) {
                return {
                    url: 'getBeneficiaryContainer',
                    credentials: 'include',
                    method: "POST",
                    body:{beneficiary_id:data}
                };
            },
            providesTags: [{type: 'app_beneficiary_container', id: 'LIST'}],
            transformResponse: (result) =>
              result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setBeneficiaries(data));
                    // console.info("Loading Setting", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        saveBeneficiaryContainer: builder.mutation({
            query(data) {
                // console.log("Query Fn",data)
                return {
                    url: 'saveBeneficiaryContainer',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['app_beneficiary_container'],
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    // await dispatch(userApi.endpoints.getMe.initiate(null));
                    // console.log("Certificate Parameter",args);
                    const { data } = await queryFulfilled;
                    // console.info("loginUser",data)
                    // await dispatch(setUser(data.data));
                    // await dispatch(setToken(data.data.token));

                } catch (error) {
                }
            },
        }),
        getInsured: builder.query({
            query() {
                return {
                    url: 'getInsured',
                    credentials: 'include',
                };
            },
            providesTags: [{type: 'app_insured', id: 'LIST'}],
            transformResponse: (result) =>
              result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setBeneficiaries(data));
                    // console.info("Loading Setting", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        getCertificateList: builder.query({
            query(args) {
                return {
                    url: 'getCertificateList',
                    credentials: 'include',
                    method: 'POST',
                    body: args
                };
            },
            providesTags: [{type: 'certificate', id: 'LIST'}],
            transformResponse: (result) =>
              result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setBeneficiaries(data));
                    // console.info("Loading Setting", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),

        getSettings: builder.query({
            query() {
                return {
                    url: 'getSettings',
                    credentials: 'include',
                };
            },
            providesTags: [{type: 'SystemSetting', id: 'LIST'}],
            transformResponse: (result) =>
                result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(setSettings(data));
                    // console.info("Loading Setting", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        editSettings: builder.mutation({
            query(data) {
                return {
                    url: 'setSettings',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['SystemSetting'],
        }),
        getCertificate: builder.query({
            query(policyNumber) {
                return {
                    url: 'getCertificate/'+policyNumber,
                    credentials: 'include',
                };
            },
            providesTags: [{type: 'certificatedata', id: "LITE"}],
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    // dispatch(setSettings(data));
                    // console.info("Loading Setting", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),
        saveCertificate: builder.mutation({
            query(data) {
                // console.log("Query Fn",data)
                return {
                    url: 'saveCertificate',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['certificate'],
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    // await dispatch(userApi.endpoints.getMe.initiate(null));
                    // console.log("Certificate Parameter",args);
                    const { data } = await queryFulfilled;
                    // console.info("loginUser",data)
                    // await dispatch(setUser(data.data));
                    // await dispatch(setToken(data.data.token));

                } catch (error) {
                }
            },
        }),
        getAttachmentList: builder.query({
            query(filter) {
                if (filter) {
                    return {
                        url: `getAttachmentList/${filter}`,
                        credentials: 'include',
                    };
                } else {
                    return {
                        url: `getAttachmentList/all`,
                        credentials: 'include',
                    };
                }

            },
            providesTags: [{type: 'app_attachment', id: 'LIST'}],
            transformResponse: (result) =>
              result.data,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                    dispatch(setSettings(data));
                    // console.info("Loading Setting", data);
                    return data
                } catch (error) {
                    console.info("Error!", error);
                }
            },
        }),

        requestCertificateCancellation: builder.mutation({
            query(data) {
                // console.log("Query Fn",data)
                return {
                    url: 'requestCertificateCancellation',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['certificate'],
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {

                    const { data } = await queryFulfilled;


                } catch (error) {
                }
            },
        }),
        confirmCertificateCancellation: builder.mutation({
            query(data) {
                // console.log("Query Fn",data)
                return {
                    url: 'confirmCertificateCancellation',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['certificate'],
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const { data } = await queryFulfilled;
                } catch (error) {
                }
            },
        }),
        verifyCertificate: builder.mutation({
            query(data) {
                // console.log("Query Fn",data)
                return {
                    url: 'verifyCertificate',
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['certificate'],
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {

                    const { data } = await queryFulfilled;

                } catch (error) {
                }
            },
        }),

    }),
});

export default appApi

export const {
    /* User */
    useGetDashboardQuery,
    useGetBeneficiaryQuery,
    useGetBeneficiaryContainerQuery,
    useSaveBeneficiaryContainerMutation,
    useGetInsuredQuery,
    useGetCertificateQuery,
    useGetAttachmentListQuery,
    useGetCertificateListQuery,
    useSaveCertificateMutation,
    useRequestCertificateCancellationMutation,
    useConfirmCertificateCancellationMutation,
    useVerifyCertificateMutation,
} = appApi