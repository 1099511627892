import React, {useRef, useState, createContext, createRef, useEffect} from "react";
import loadable from "@loadable/component";
// import {
//     createBrowserRouter,
//     RouterProvider,
// } from "react-router-dom";

import {Routes, Route, useLocation} from "react-router-dom";
// import { ProgressSpinner } from 'primereact/progressspinner';
import {Helmet} from "react-helmet-async";

import Skel from './skel/skel'
import Anim from "./skel/anim";

import Login from './login/login'
import Register from './login/register'
import SubmitVerification from './login/submitVerification';

import Sidebar from "./skel/sidebar";
import Dashboard from './dashboard/dashboard'

// Switch to lazyloading
// import ManageProfile from './profile/profile';
// import ManageUser from './admin/manageUser'
// import ManageUserProfile from './admin/manageProfile'
// import ManageTariff from './admin/manageTariff'
// import ManageBalance from './admin/manageBalance'
// import ManageRole from './admin/manageRole'
// import CustomerRegister from './customer/register'
// import RequireUser from '../components/requireUser';
// import CreateNewPolicy from '../containers/policy/createPolicy';
// import ViewPolicy from '../containers/policy/viewPolicy';
// import CancelPolicy from '../containers/policy/cancelPolicy';
// import CancelRequestPolicy from '../containers/policy/cancelRequestPolicy';
// import CancelledPolicy from '../containers/policy/cancelledPolicy';
// import VerifyPolicy from '../containers/policy/verifyPolicy';
// import VerifyPolicyLite from '../containers/policy/verifyPolicyLite';
// import ViewPolicyReport from '../containers/policy/viewReport';
// import ManageContainer from '../containers/profile/containerType';

import NotFound from './skel/notfound'
import Loading from './skel/loading'

import {useAppSelector} from "../app/store";
import {useGetSettingsQuery} from "../features/system/systemApi";
import {useCookies} from "react-cookie";
import {useLoginUserWithCookieMutation} from "../features/auth/authApi";

import {ToastContainer, Flip, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {AuthProvider, AuthContext} from "./authProvider";
import Settings from "../settings"
import Metadata from "../generatedMetadata.json"

const ManageProfile = loadable(() => import('./profile/profile'), {fallback: <Loading/>});
const ManageUser = loadable(() => import('./admin/manageUser'), {fallback: <Loading/>});
const ManageUserProfile = loadable(() => import('./admin/manageProfile'), {fallback: <Loading/>});
const ManageTariff = loadable(() => import('./admin/manageTariff'), {fallback: <Loading/>});
const ManageBalance = loadable(() => import('./admin/manageBalance'), {fallback: <Loading/>});
const ManageRole = loadable(() => import('./admin/manageRole'), {fallback: <Loading/>});
const ManageSession = loadable(() => import('./admin/manageSession'), {fallback: <Loading/>});
const ManageAnnouncement = loadable(() => import('./admin/manageAnnouncement'), {fallback: <Loading/>});
const CustomerRegister = loadable(() => import('./customer/register'), {fallback: <Loading/>});
const RequireUser = loadable(() => import('../components/requireUser'), {fallback: <Loading/>});
const CreateNewPolicy = loadable(() => import('../containers/policy/createPolicy'), {fallback: <Loading/>});
const ViewPolicy = loadable(() => import('../containers/policy/viewPolicy'), {fallback: <Loading/>});
const CancelPolicy = loadable(() => import('../containers/policy/cancelPolicy'), {fallback: <Loading/>});
const CancelRequestPolicy = loadable(() => import('../containers/policy/cancelRequestPolicy'), {fallback: <Loading/>});
const CancelledPolicy = loadable(() => import('../containers/policy/cancelledPolicy'), {fallback: <Loading/>});
const VerifyPolicy = loadable(() => import('../containers/policy/verifyPolicy'), {fallback: <Loading/>});
const VerifyPolicyLite = loadable(() => import('../containers/policy/verifyPolicyLite'), {fallback: <Loading/>});
const ViewPolicyReport = loadable(() => import('../containers/policy/viewReport'), {fallback: <Loading/>});
const ViewPolicyReportLocal = loadable(() => import('../containers/policy/viewReportLocal'), {fallback: <Loading/>});

const ManageContainer = loadable(() => import('../containers/profile/containerType'), {fallback: <Loading/>});
const MainLayout = () => {
  // const {user,settings} = React.useContext(AuthContext);

  // console.log("MainLayout", user)
  return (<AuthProvider>
    <Helmet
      // titleTemplate={"%s :: " + (Settings?.APP_NAME || "No name")}
      titleTemplate={(Settings?.APP_NAME || "No name") + " - %s "}
      defaultTitle={Settings?.APP_NAME || "No name"}
    >
      <meta charSet="utf-8"/>
      <title>{Settings?.APP_NAME || "No name"}</title>
      <link rel="canonical" href="https://container.tssc.co.th"/>
    </Helmet>

    <ToastContainer transition={Flip}/>
    {/*<Toast ref={toast}/>*/}
    <Routes>

      <Route path="/verify/:policyNumber" element={<VerifyPolicyLite/>}/>
      <Route path={"/"} element={<Skel/>}>
        <Route element={<RequireUser/>}>
          <Route path={"/"} element={<Sidebar/>}>
            <Route path='*' element={<NotFound/>}/>
            <Route exact path={"/"} element={<Dashboard/>}/>
            <Route exact path={"/dashboard"} element={<Dashboard/>}/>
            <Route exact path={"/profile"} element={<ManageProfile/>}/>
            <Route exact path={"/customer/register"} element={<CustomerRegister/>}/>

            {/*Admin Only*/}
            <Route element={<RequireUser allowedRoles={['Super Admin', 'Admin']}/>}>
              <Route exact path={"/manage/role"} element={<ManageRole/>}/>
              <Route exact path={"/manage/user"} element={<ManageUser/>}/>
              <Route exact path={"/manage/profile"} element={<ManageUserProfile/>}/>
              <Route exact path={"/manage/tariff"} element={<ManageTariff/>}/>
              <Route exact path={"/manage/balance"} element={<ManageBalance/>}/>
              <Route exact path={"/manage/session"} element={<ManageSession/>}/>
              <Route exact path={"/manage/announce"} element={<ManageAnnouncement/>}/>

              <Route path='/admin' element={<div>admin page</div>}/>
            </Route>
            <Route element={<RequireUser allowedRoles={['Super Admin', 'Admin', 'Beneficiary']}/>}>
              <Route exact path="/containerType" element={<ManageContainer/>}/>

            </Route>

            <Route element={<RequireUser allowedRoles={['Insured', 'Admin', 'Beneficiary']}/>}>
              <Route exact path="/policy/new" element={<CreateNewPolicy/>}/>
              <Route exact path="/policy/view" element={<ViewPolicy/>}/>
              <Route exact path="/policy/cancel" element={<CancelPolicy/>}/>
              <Route exact path="/policy/cancelRequest" element={<CancelRequestPolicy/>}/>
              <Route exact path="/policy/cancelled" element={<CancelledPolicy/>}/>
              <Route exact path="/policy/verify" element={<VerifyPolicy/>}/>
              <Route exact path="/policy/report" element={<ViewPolicyReport/>}/>
              <Route exact path="/policy/reportLocal" element={<ViewPolicyReportLocal/>}/>

            </Route>
            <Route element={<RequireUser allowedRoles={['Super Admin', 'Admin', 'Registered']}/>}>
              <Route path="submitVerification" element={<SubmitVerification/>}/>
            </Route>
            <Route path='unauthorized' element={<div>unauthorized page</div>}/>
          </Route>
        </Route>
        <Route path="login" element={<Login/>}/>
        <Route path="register" element={<Register/>}/>

        <Route path='*' element={<NotFound/>}/>
      </Route>


    </Routes>

    <div style={{
      position: "absolute",
      bottom:0, right: 20,
      textShadow: "1px 1px #777", fontWeight: "bold",
      color: "rgba(231,231,231,0.43)",
      transform: "rotate(90deg)",
      transformOrigin: "right center"
    }}>{Metadata.versionString}</div>

  </AuthProvider>);

}

export default MainLayout