import React, {useRef, useState, useEffect} from "react";
import {Route, NavLink, useHistory, useLocation, useNavigate} from "react-router-dom";
import {useMediaQuery} from 'react-responsive'

import {InputText} from "primereact/inputtext";
import {Badge} from "primereact/badge";
import {StyleClass} from "primereact/styleclass";
import {Ripple} from "primereact/ripple";
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {Divider} from 'primereact/divider';

import {Form, Field} from "react-final-form";

import {Helmet} from "react-helmet-async";

import {useRegisterUserMutation} from '../../features/auth/authApi';
import {getUser} from '../../features/auth/userSlice';
import {useAppSelector} from "../../app/store";

import { toast } from 'react-toastify';

const RegisterPage = () => {
    const initialForm = {login: '', contactName: "", phone: "", newpassword: "", newpasswordconfirm: ""}
    const [registerUser, registerUserResult] = useRegisterUserMutation()
    const user = useAppSelector(state => state.user)

    const navigate = useNavigate();
    const location = useLocation();
    const from = ((location.state)?.from.pathname) || '/';

    const isLarge = useMediaQuery({query: '(min-width: 992px)'})

    useEffect(() => {
        // console.info("Use Effect Trigger")
        if (registerUserResult.isSuccess) {
            // console.info("Use Effect Trigger - isSuccess")
            toast.info("You successfully register and logged in");
            navigate("/submitVerification");
        }
        if (registerUserResult.isError) {
            // console.info("Use Effect Trigger - isError")
            toast.warn('Please recheck your informations.');
        }

        if (user && user.user) {
            // console.info("User found", user);
            navigate("/submitVerification");
        }
        // console.info("Debug",user)
    }, [registerUserResult]);

    const [checked3, setChecked3] = useState(false);

    const [displayMode, setDisplayMode] = useState("login");


    const items = [
        {label: 'Add New', icon: 'pi pi-fw pi-plus'},
        {label: 'Remove', icon: 'pi pi-fw pi-minus'}
    ];

    const onSubmit = async values => {
        // window.alert(JSON.stringify(values, 0, 2))
        registerUser(values)
    }

    return (<>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>Registration</title>
            <link rel="canonical" href="https://www.tssc.co.th"/>
        </Helmet>
        <div style={{
            background: 'url("assets/images/blocks/signin/signin-2.jpg") no-repeat',
            minHeight: "100vh",
            backgroundSize: 'cover'
        }}
             className="px-4 py-8 md:px-6 lg:px-8">
            <div className="flex flex-wrap" style={{minHeight: "80vh"}}>
                <div className="w-full lg:w-3 p-4 lg:p-7"
                     style={{backgroundColor: 'rgba(255,255,255,.7)', order: isLarge ? 1 : 2}}>
                    <img src="assets/images/blocks/logos/bastion-purple.svg" alt="Image" height="50" className="mb-6"/>
                    <div className="text-xl text-black-alpha-90 font-500 mb-3">Terms of registration.</div>
                    <p className="text-black-alpha-50 line-height-3 mt-0 mb-6">Demo context 1</p>
                    <ul className="list-none p-0 m-0">
                        <li className="flex align-items-start mb-4">
                            <div>
                        <span className="flex align-items-center justify-content-center bg-purple-400"
                              style={{width: '38px', height: '38px', borderRadius: '10px'}}>
                            <i className="text-xl text-white pi pi-inbox"></i>
                        </span>
                            </div>
                            <div className="ml-3">
                                <span className="font-medium text-black-alpha-90">Unlimited Inbox</span>
                                <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">Demo context 2 </p>
                            </div>
                        </li>
                        <li className="flex align-items-start mb-4">
                            <div>
                        <span className="flex align-items-center justify-content-center bg-purple-400"
                              style={{width: '38px', height: '38px', borderRadius: '10px'}}>
                            <i className="text-xl text-white pi pi-shield"></i>
                        </span>
                            </div>
                            <div className="ml-3">
                                <span className="font-medium text-black-alpha-90">Premium Security</span>
                                <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">Demo context 3</p>
                            </div>
                        </li>
                        <li className="flex align-items-start">
                            <div>
                        <span className="flex align-items-center justify-content-center bg-purple-400"
                              style={{width: '38px', height: '38px', borderRadius: '10px'}}>
                            <i className="text-xl text-white pi pi-globe"></i>
                        </span>
                            </div>
                            <div className="ml-3">
                                <span className="font-medium text-black-alpha-90">Cloud Backups Inbox</span>
                                <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">Demo context 4</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <Form
                    onSubmit={onSubmit}
                    initialValues={{...initialForm}}
                    render={({handleSubmit, form, submitting, pristine, values}) => (
                        <div className="w-full lg:w-9 p-4 lg:p-7"
                             style={{backgroundColor: 'rgba(159,255,252,0.7)', order: isLarge ? 2 : 1}}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className="text-900 text-2xl font-medium mb-6">Registration</div>
                                <div className="grid formgrid p-fluid">
                                    <div className="field mb-4 col-6 md:col-4">
                                        <label htmlFor="contactName" className="block text-900 font-medium mb-2">Contact
                                            Name</label>
                                        <Field name="contactName">
                                            {props => (
                                                <InputText
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    onChange={props.input.onChange}
                                                    id="contactName" type="text" className="w-full"/>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="field mb-4 col-6 md:col-4">
                                        <label htmlFor="login"
                                               className="block text-900 font-medium mb-2">Email/Username</label>
                                        <Field name="login">
                                            {props => (
                                                <InputText
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    onChange={props.input.onChange}
                                                    id="email" type="text" className="w-full"/>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="field mb-4 col-6 md:col-4">
                                        <label htmlFor="phone"
                                               className="block text-900 font-medium mb-2">Phone</label>
                                        <Field name="phone">
                                            {props => (
                                                <InputText
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    onChange={props.input.onChange}
                                                    id="phone" type="text" className="w-full"/>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="field mb-4 col-6 md:col-6">
                                        <label htmlFor="password" className="block text-900 font-medium mb-2"
                                        >Password</label>
                                        <Field name="newpassword">
                                            {props => (
                                                <InputText
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    onChange={props.input.onChange}
                                                    autoComplete="newpassword"
                                                    id="newpassword" type="password" className="w-full"/>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="field mb-4 col-8 md:col-6">
                                        <label htmlFor="password" className="block text-900 font-medium mb-2"
                                        >Password Confirm</label>
                                        <Field name="newpasswordconfirm">
                                            {props => (
                                                <InputText
                                                    name={props.input.name}
                                                    value={props.input.value}
                                                    onChange={props.input.onChange}
                                                    autoComplete="newpassword"
                                                    id="newpasswordconfirm" type="password" className="w-full"/>
                                            )}
                                        </Field>
                                    </div>
                                    <div className="field mb-4 col-12 md:col-12">
                                        เมื่อลงทะเบียนเสร็จแล้ว กรุณาส่งเอกสารยืนยันตัวตน
                                        (หรือล็อคอินเข้าระบบเพื่อนำส่งเอกสารภายหลัง)
                                    </div>
                                </div>

                                <Button label="Sign up" icon="pi pi-user" className="w-full" type="submit"
                                        disabled={submitting}/>

                                {/*<Divider align="center" className="my-6">*/}
                                {/*    <span className="text-600 font-normal text-sm">OR</span>*/}
                                {/*</Divider>*/}

                                {/*<Button label="Sign In with GitHub" icon="pi pi-github"*/}
                                {/*        className="w-full p-button-secondary"/>*/}

                                {/*<div>&nbsp;</div>*/}
                                {/*<Button label="Test" icon="pi pi-github"*/}
                                {/*        className="p-button-link"*/}
                                {/*        onClick={()=>{console.info(getUser())}}*/}
                                {/*/>*/}

                                <div className="mt-6 text-center text-blue-600">
                                    Already registered ?<br/>
                                    <a tabIndex="0" className="font-medium text-dark-blue-500"
                                       onClick={() => {
                                           navigate("/login")
                                       }}>Sign in</a>
                                </div>
                            </form>
                        </div>

                    )}
                />


            </div>
        </div>

    </>);

}

export default RegisterPage