import React, {useRef, useState, createContext, createRef, useEffect} from "react";
import logo from './logo.svg';
import {Counter} from './features/counter/Counter';
import MainLayout from './containers/mainLayout';
import {BrowserRouter as Router, Switch, Route, NavLink, useHistory, useLocation} from "react-router-dom";

// import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import './assets/theme.css'
import './App.scss';

function App() {

    return (
        <div className="App">
                <MainLayout/>
        </div>
    );
}

export default App;
