import {BreadCrumb} from "primereact/breadcrumb";
import React from "react";
import { NavLink } from "react-router-dom";

export const StyledBreadcrumb = (props) => {
    const breadcrumbMapper = (menu) => {
        if (!Array.isArray(menu)) return <></>
        return menu.map((menuItem, menuIndex) => {
            // console.info(menuItem,menuIndex,menu.length)
            // console.log(menuItem)
            if (menuItem.to == "") menuItem.to = "#"
            return menuIndex < (menu.length-1)
                ? <React.Fragment key={"breadcrumb-"+menuIndex}>
                    <li className="px-2" >
                        <NavLink className="cursor-pointer text-blue-500 white-space-nowrap font-medium no-underline"
                           to={menuItem.to ? menuItem.to : null}>{menuItem.label || "-"}</NavLink>
                    </li>
                    <li className="px-2">
                        <i className="pi pi-angle-right text-500 font-medium no-underline"></i>
                    </li>
                </React.Fragment>
                : <React.Fragment key={"breadcrumb-"+menuIndex}><li className="px-2">
                    <NavLink className="cursor-pointer text-500 white-space-nowrap font-medium no-underline"
                       to={menuItem.to ? menuItem.to : null}>{menuItem.label || "-"}</NavLink>
                </li></React.Fragment>
        })
    }

    return (
        <ul className="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border shadow-2 lg:shadow-none">
            <li className="pr-3">
                <NavLink className="cursor-pointer" to={"/"}>
                    <i className="pi pi-home text-blue-500  font-medium"></i>
                </NavLink>
            </li>
            <li className="px-2">
                <i className="pi pi-angle-right text-500 font-medium"></i>
            </li>
            {breadcrumbMapper(props.menu)}
        </ul>
    )
}